.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.scan-container.wd_container {
  align-items: start;
  flex-direction: column;
}

.bs_main label,
.placeholder_info {
  font-size: 13px;
  color: var(--body_color);
}

.bs_main label {
  padding-bottom: 5px;
}

.select_container {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 8px 16px;
}

.font_23x {
  font-size: 23px;
  color: #5f5f5f;
}

.disabled_button {
  width: fit-content;
  background: rgba(95, 95, 95, 0.2);

  height: 42px;
  height: 55px;

  font-size: 20px;
  border-radius: 5px;
}

.w_fiat {
  width: 90%;
  margin: auto;
}

.w_fiat button {
  width: fit-content;
}

.font_13x {
  font-size: 13px;
}

.danger_disabled {
  color: rgba(95, 95, 95, 1);

  border-color: rgba(95, 95, 95, 0.2);
}

.W_form .ant-row {
  display: block;
}

.W_form .ant-col-16 {
  max-width: 100%;
}

.W_form input {
  height: 55px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  font-size: 15px;
}

.W_form input:hover {
  border: 1px solid #d7d7d7;
}

.W_form {
  height: 350px;
  overflow-y: auto;
}

.W_form label,
.placeholder_info {
  color: #5f5f5f;
}

.border-t-1x {
  border-top: 1px solid #d7d7d7;
}

.border-1x {
  border: 1px solid #d7d7d7;
}

.max_400 {
  max-width: 400px;
}

.padding-1x {
  padding: 10px;
}

.brand_opacity_5 {
  color: rgba(95, 95, 95, 0.5);
}

.padding-tb-1x {
  padding-top: 10px;
  padding-bottom: 10px;
}

.flex_buttons button {
  width: 47%;
}

.ant-radio-inner::after {
  background-color: var(--primary-color);
}

.ant-radio-checked::after,
.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color);
}

.ant-radio-wrapper,
.ant-radio-wrapper span {
  font-size: 15px;
}

.wd_container .helper_text {
  color: rgba(95, 95, 95, 0.5);
  font-weight: normal;
  font-family: AvenirRegular;
}

.wd_container .radio_text {
  font-family: AvenirHeavy;
  color: #5f5f5f;
  font-size: 16px;
}

.w_50 {
  width: calc(50% - 20px);
}

.font_weight_800 {
  font-weight: 800;
}

.font_40x {
  font-size: 40px;
}

.deposit-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 54px !important;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 21%;
}

.bs_main label.ant-radio-wrapper {
  color: #5f5f5f;
}

.bg_icon {
  background: var(--primary-color);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  padding: 2px;
}

.W_form .ant-col-8 {
  max-width: 100%;
}

.brand_color {
  color: #5f5f5f;
}

.popover_container {
  width: 300px;
  min-height: 304px;
}

.disc_ul {
  list-style-type: disc;
  margin-left: 20px;
}

.ant-popover-inner-content {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.margin-l-1x {
  margin-left: 10px;
}

.ant-popover-inner {
  border-radius: 5px;
}

.popover_container_link,
.popover_container_link:hover {
  color: #5f5f5f;
  text-decoration: underline;
}

.amount_container input {
  border: none;
}

.ant-btn.disabled_button:hover,
.ant-btndisabled_button:focus {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.text_line {
  text-decoration: underline;
}

.ant-select-selection-placeholder {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
}

.border-r-1x {
  border-right: 1px solid #5e5e5e;
}

.padding-r-1x {
  padding-right: 10px;
}

.padding-l-1x {
  padding-left: 10px;
}

.container {
  margin: auto;
  max-width: 1200px;
}

.graph {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.graph h3 {
  text-align: center;
}

.graphBackground {
  background-color: var(--body_background);
  width: auto;
  height: auto;
}

.xscroll {
  overflow: scroll;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #112d3f;
  color: white;
  width: 85px;
  height: 23px;
  margin-right: 3rem;
  cursor: pointer;
  border: none;
  margin: 0.4rem;
  border-radius: 5px;
}

.button:hover {
  background-color: #236086;
}

/* .Chart_inner {
  padding: 10px 0;
} */

.chart_inner_right .chart_dynamic .ant-segmented-item-label {
  float: right;
  width: 65px;
}

.chart_inner_right .chart_dynamic label {
  padding-bottom: 100;
}

/* .chart_header {
    display:flex;
    align-items:center;
} */
.chart_header h1 {
  margin-bottom: 0;
  font-size: 30px;
  color: #5f5f5f;
  line-height: 1;
}

.chart_title {
  padding: 0 7px;
}

.arrow_container div {
  line-height: 0;
  padding-bottom: 2px;
}

/* .Chart_inner {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 12px 0;
  align-items: baseline; */
  /* padding-right: 76px; */
/* } */

/* .chart_inner_left_top {
  display:flex;
} */

.chart_inner_middle {
  color: var(--primary-color);
  font-size: 20px;
}

.chart_inner_right .ant-segmented {
  /* background-color: rgba(129, 129, 129, 0.1); */
  font-size: 15px;
  /* color: #5f5f5f; */
  padding: 0;
  border-radius: 5px;
}

.chart_inner_right .ant-segmented-item-label {
  width: 65px;
  font-size: 15px;
  min-height: 37px;
  line-height: 37px;
}

.chart_inner_right .chart_dynamic label {
  padding-bottom: 0;
}

.chart_inner_right .chart_dynamic .ant-segmented-item-label {
  width: 65px;
}

.bs_main .chart_inner_right .ant-segmented-item-selected {
  /* background: #006DFF;
  color:#fff; */
  background: var(--primary-color);
  /* color: #000; */
  color: var(--dark-text);
  background-color: transparent;
  border-radius: 5px;
}

.time {
  /* display:flex;
  justify-content: space-between; */
  padding: 10px 15px;
}

.time div {
  color: #5f5f5f;
  font-size: 20px;
}

.tooltip {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
}

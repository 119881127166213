.border,
.ant-input-affix-wrapper {
  border: 1px solid rgba(0, 109, 255, 0.2);
  border-radius: 5px;
}

.scan-container.market-container {
  flex-direction: column;
  color: var(--body_color);
}

.search-input-container {
  width: 508px;
  height: 44px;
}

.ant-input-prefix {
  border-right: 1px solid rgba(0, 109, 255, 0.2);
  padding-right: 7px;
}

.tabs-container {
  width: 95%;
  margin: 67px auto 40px;
  min-height: 40px;
}

.market-container .ant-tabs-top > .ant-tabs-nav::before,
.market-container .ant-tabs-bottom > .ant-tabs-nav::before,
.market-container .ant-tabs-top > div > .ant-tabs-nav::before,
.market-container .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.market-container .ant-tabs-nav {
  padding-left: 60px;
}

.grey-strip {
  background: #d9d9d966;
  padding: 24px 21px 19px;
}

.white-strip {
  background: var(--market-table-color);
  color: var(--body_color);
  min-width: 100px;
  height: 32px;
  border-radius: 5px;
  width: auto;
}

.white-strip:hover {
  background: var(--market-table-color);
  /* color: var(--body_color); */
}

.margin-lr-2x {
  margin-left: 20px;
  margin-right: 20px;
}

.white-strip.last-item {
  width: auto;
  margin-left: auto;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}

.tab-body-container .ant-table-content {
  margin: 0 20px;
}

.market-container .ant-tabs-tab {
  color: rgba(95, 95, 95, 0.5);
}

.market-container .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 30px;
  color: var(--body_color);
}

.market-container .ant-tabs-tab:hover .ant-tabs-tab-btn {
  font-size: 30px;
  color: var(--body_color);
}

.market-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--body_color);
  font-size: 30px;
}

.market-container .ant-tabs-ink-bar {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.market-container .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 45px;
}

.ant-btn.btn-success {
  background-color: var(--primary-color);
  border-color: transparent;
  border-radius: 4px;
}

.ant-btn.btn-success:hover {
  background-color: var(--secondary-color);
  border-color: transparent;
  border-radius: 4px;
}

.ant-btn.btn-warn {
  border-color: transparent;
  border-radius: 4px;
}

.ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}

.ant-table-pagination.ant-pagination {
  display: none;
}

.market-container .ant-spin-dot {
  width: 50px;
  height: 50px;
}

@media (max-width: 767px) {
  .market_button_strips button {
    min-width: 50px;
    max-width: 90px;
    font-size: 12px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .market_button_strips .margin-lr-2x {
    margin-left: 8px;
    margin-right: 0;
  }

  .market-container .tab-body-container .ant-table-content {
    margin: 0 0;
  }

  .market-container .ant-tabs-tab + .ant-tabs-tab,
  .market-container .ant-tabs-tab:hover .ant-tabs-tab-btn,
  .market-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .market-container .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 15px;
  }

  .market-container .ant-tabs-nav {
    padding-left: 0;
  }

  .market-container h1 {
    font-size: 25px;
    padding: 0 16px;
  }

  .market-container .search-input-container {
    width: 100%;
    padding: 0 20px;
  }

  .market-container .ant-tabs-tab:nth-child(2) {
    margin-left: 20px;
  }
}



/* .ant-select, .ant-pagination-options {
  color: var(--body_color) !important;
  background-color: var(--body_background) !important;
} */

.ant-select-selector{
  /* color: var(--body_color) ; */
  background-color: var(--body_background) !important;
}
.ant-select{
border: 1px solid var(--border-color);
border-radius: 6px;
}
.ant-select-arrow, .ant-select-selection-item{
  color: var(--body_color) !important;

}
.ext-tabs .ant-tabs-ink-bar {
  background-color:  var(--body_color) !important;
}

.ext-tabs .MuiTabs-indicator{
  background-color:  var(--body_color) ;
  height: 0.7px;
  transform: translateX(20%); 
  width:70px !important
}

.ext-tabs{
  background: var(--main-body);
  color: var(--body_color);
  width: 100vw;
  z-index: 999;
  height: 90px;
  padding-top:15px;
  padding-bottom:100px;
  position: fixed;
}

.other-tabs{
  background: #FFB300;
  color: #000;
  width: 100vw;
  z-index: 999;
  height: 90px;
  padding-top:5px;
  padding-bottom:85px;
  position: fixed;
}

.other-tabs .MuiTabs-indicator{
  background-color:  #000 !important ;
  height: 0.7px;
  transform: translateX(50%); 
  width:50px !important
}

.tab-format{
  width: 100px !important;
  text-transform: none !important;
  color: inherit !important;
  font-size: 10px !important;
  padding: 0 !important;
  min-height: 70px !important;
}

.tab-format:hover, 
.tab-format:focus, 
.tab-format:active 
{
  background: transparent;
}

@media (max-width: 767px) {

  .tab-format{
    width: 40px !important;
  }
}
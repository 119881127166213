.coll {
  border: 1px solid var(--border-color) !important;
  border-top: none !important;
  border-top: none;
  margin-bottom: 100;
  color: #5f5f5f;
  width: 1340px;
}
.coll .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-radius: 0 !important;
  color: var(--body_color);
  border-top: none;
}
.coll .ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
  color: var(--primary-color);
}

/* .coll .ant-collapse {
  border: 1px solid var(--border-color) !important;
} */
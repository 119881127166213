@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
:root {
  --main-body: #f4f4f6;
  --body_background: #f4f4f6;
  --body_color: #333336;
  --dark_text: #000;
  --err-clr: rgba(246, 96, 54, 0.2);
  --light-gr-color: rgba(18, 18, 18, 0.4);
  --disabled-btn: #f5f5f5;
  --border-color: #5f5f5f36;
  --conf-purchase: rgba(96, 96, 96, 0.5);
  --dash-bg: #f6f6f6;
  --hive-honeybee: #e7e7e7;
  --calendar-disabled: #e6e6e6;
  --cal-bg: #eaf6ff;
  --link_color: #2607f3;
  --table_header: #939292;
  --table-border: #e6e8ea;
  --section3-unselected: rgba(0, 0, 0, 0.15);
  --blog-border-orange: rgba(246, 96, 54, 0.2);
  --market-table-color: #fff;

  --background-image: url(/static/media/Black_withdraw.11ac940a.svg);

  --disabled-btn-text: rgba(0, 0, 0, 0.25);
  --timeline: #e0e0e0;

  --primary-color: #11be6a;
  --secondary-color: #0ea55a;
  --highlight-color: lightgreen;
  --link-hover: #1890ff;
  --dropdown-hover: #1890ff;
  --staking-color: #e7e7e7;
  --table-light: #eeeeee;
  --scroller: 193, 193, 193;
  --overlay: 175, 175, 175;
}

[data-bs-theme='dark'] {
  --main-body: #181a21;
  --body_background: #171717;
  --body_color: #e8e8ed;
  --dark_text: #fff;
  --err-clr: rgba(246, 96, 54, 0.5);
  --light-gr-color: rgba(226, 220, 220, 0.5);
  --disabled-btn: #3231319f;
  --border-color: #2c2c2c;
  --conf-purchase: rgba(212, 212, 212, 0.685);
  --dash-bg: #151414;
  --hive-honeybee: #aeaeae;
  --timer-color: #2d2d2d;
  --calendar-disabled: #2c2c2c;
  --cal-bg: #263e51;

  --link_color: #f307b8;
  --table_header: #aeaeae;
  --table-border: #3c3b3b;
  --section3-unselected: rgba(255, 255, 255, 0.25);
  --blog-border-orange: rgba(59, 130, 246, 0.3);
  --market-table-color: #575757;
  --background-image: url(/static/media/Grey_withdraw.72982a32.svg);

  --disabled-btn-text: rgba(135, 132, 132, 0.785);
  --timeline: #3231319f;

  --primary-color: #11be6a;
  --secondary-color: #0ea55a;
  --highlight-color: lightgreen;
  --link-hover: #1890ff;
  --dropdown-hover: #1890ff;
  --staking-color: #171c26;
  --table-light: #040404;
  --scroller: 46, 46, 46;
  --overlay: 0, 0, 0;
}

@font-face {
  font-family: AvenirLight;
  src: url(/static/media/AvenirLight.4343d6ec.ttf) format('truetype');
}

@font-face {
  font-family: AvenirRegular;
  src: url(/static/media/AvenirRoman.0bfb049d.ttf) format('truetype');
}

@font-face {
  font-family: AvenirMedium;
  src: url(/static/media/AvenirMedium.adcbfbb8.ttf) format('truetype');
}

@font-face {
  font-family: AvenirHeavy;
  src: url(/static/media/AvenirBlack.7ce894e1.ttf) format('truetype');
}

html {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-body) !important;
  color: var(--body_color) !important;
  padding-right: 0 !important;
  overflow: auto !important;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--body_color) !important;
}

.flex-align-start {
  align-items: start;
}

div.flex-align-center {
  align-items: center;
}

div.flex-align-stretch {
  align-items: stretch;
}

div.flex-justify-center {
  justify-content: center;
}

.mb-0 {
  margin-bottom: 0;
}

.card {
  min-width: 440px;
  background-color: transparent;
  color: var(--body_color);
  border: 1px solid rgba(0, 109, 255, 0.5);
  min-height: 600px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.card__header {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 109, 255, 0.5);
}

.card__header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__header__inner__left {
  flex: 1 1;
}

.card__header__inner__right {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.card__body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card__footer {
  text-align: center;
  padding: 20px;
}

.card__title {
  color: #5f5f5f;
  font-size: 40px;
  margin-bottom: 0;
  flex: 1 1;
  line-height: 1.2;
}

.orange.card {
  border: #0ea55a;
}

.tag {
  font-size: 1em;
  font-family: AvenirHeavy;
  border-radius: 10px;
  padding: 5px 10px 2px;
  background-color: #f2f2f2;
  color: #5f5f5f;
}

.input__field {
  border: 1px solid rgba(0, 109, 255, 0.5);
  border-radius: 5px;
  height: 56px;
  line-height: 60px;
}

.input__field input {
  text-align: right;
  font-size: 25px;
  padding-right: 30px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.index_tokens img {
  width: 176px;
  height: 176px;
}

.font_25x {
  font-size: 25px;
}

@media (max-width: 767px) {
  .card {
    min-width: -webkit-fill-available;
  }
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  color: var(--body_color);
  background-color: transparent !important;
  border: none;
}

.ant-pagination-item-active {
  color: var(--dark_text) !important;
  font-weight: bolder;
  background-color: transparent !important;
  font-size: 16px;
}

.ant-pagination-item-link,
.ant-pagination-item-ellipsis {
  background-color: transparent !important;
  color: var(--body_color) !important;
  border: none !important;
}

.App {
  text-align: center;
  background-color: var(--body_background);
  color: var(--body_color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-select-arrow {
  height: 20px;
  font-size: 19px;
  transform: rotate(-90deg);
  color: #5f5f5f;
}

.pointer-events-none {
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hive-img{
  transition: transform 330ms ease-in-out;
}

.hive-img:hover{
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

.tradingview-widget-copyright{
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.chart_inner_middle {
  font-size: 30;
}

.chart_inner_right {
  margin-top: -35px;
  margin-right: -25px;
}

.chart_inner_right .ant-segmented {
  background: var(--body_background);
  float: right;
  font-size: 15px;
  padding: 0;
  border-radius: 5px;
}

.chart_inner_right .ant-segmented:hover {
  background: var(--body_background);
  float: right;
  font-size: 15px;
  padding: 0;
  border-radius: 5px;
  /* color: rgba(var(--body_color), var(--alpha)) !important; */
}

.chart_inner_right .ant-segmented-item-label {
  width: 65px;
  font-size: 15px;
  min-height: 37px;
  line-height: 37px;
  background: var(--body_background);
  color: rgba(var(--body_color), var(--alpha)) !important;
}

.chart_inner_right .ant-segmented-item{
  background: var(--body_background);
  color: var(--body_color);
  opacity: 0.5;
}

.chart_inner_right .ant-segmented-thumb {
  background: var(--body_background) !important;
  opacity: 1;
}

.chart_inner_right .ant-segmented-item-selected {
  background: var(--dark-text);
  font-weight: bolder;
  color: var(--body_color);
  border:none;
  box-shadow: none;
  opacity: 1;
}

@media (max-width: 420px) {

  .chart_inner_right {
    margin-top: 5px;
    margin-right: 0px;
  }

}
/* Default theme */
.theme-normal {
  --primary-color: #11be6a;
  --secondary-color: #0ea55a;
  --highlight-color: lightgreen;
  --link-hover: #1890ff;
  --dropdown-hover: #11be6a;
}

/* CaptainBee theme */
.theme-captain {
  --primary-color: #ffb300;
  --secondary-color: #FFD000;
  --highlight-color: #ffb300;
  --link-hover: grey;
  --dropdown-hover: #ffb300;

}

/* HoneyBee theme */
.theme-honeyb {
  --primary-color: #ffb300;
  --secondary-color: #FFD000;
  --highlight-color: #ffb300;
  --link-hover: grey;
  --dropdown-hover: #ffb300;
}

.home-container {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100vw 900px;
  flex-direction: column;
}

.center-Image {
  height: 136px;
  margin-top: -100px;
}

.section-heading {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 45px;
  text-align: center;
}

.section-helper-text {
  font-family: AvenirMedium, serif;
  font-size: 20px;
  color: white;
  padding: 10px 20px 25px;
  text-align: center;
}

.section-usp {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 20px;

  padding-top: 120px;
  padding-bottom: 70px;
}

.font-big {
  color: white;
  font-size: 45px;
}

.big_trade_font {
  font-size: 120px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
}

.cut_button {
  width: 200px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  cursor: pointer;
  text-align: center;
}

.login-button {
  border-bottom: 50px solid var(--primary-color);
  border-left: 25px solid transparent;
  margin-right: -20px;
  margin-bottom: -42px;
}

.login-button:hover {
  border-bottom-color: var(--secondary-color);
  border-left-color: transparent;
}

.launch-app-button {
  border-top: 50px solid #006dff;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}

.launch-app-button:hover {
  border-top: 50px solid #00387e;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}

.launch-app-button-text {
  margin-bottom: 48px;
}

.login-button_text {
  margin-top: 48px;
}

.td-none {
  text-decoration: none !important;
}

.section2-container {
    display: flex;
    padding: 0;
    margin: 0;
    margin-top: -100px;
    flex-direction: column;
}

.section2-table {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #E6E8EA;
}

.table-row {
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.table-header-element {
    color: #939292;
    font-family: AvenirRegular, serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    width: 25%;
}

.no-border {
    border: none !important;
}

.coinName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.coin-initials {
    color: black;
    padding: 0 6px;
}

.price {
    color: black;
}

.hourChange {
    color: #e33e4e;
}

.all_markets {
    text-align: center;
    padding: 50px 0;
}

.view_all_btn {

    height: 60px;
    border-radius: 0;
}


@media (max-width: 767px) {
    .table-header-element {
        text-align: center;

    }

    .table-row {
        padding-left: 0;
        padding-right: 0;
        justify-content: space-between;
    }

    .table-row>div {
        margin-right: 10px;
    }

    .coinName span {
        display: none;
    }



    .section2-table {

        margin: 0 10px;
        width: calc(100% - 20px);
    }

    .chart-icon {
        max-width: 98px;
    }
}
.section3-container {
  display: flex;
  padding: 20px;
  margin: 20px;
}

.data-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  cursor: pointer;
}

.label-container {
  font-family: AvenirHeavy, serif;
  font-size: 40px;
  font-weight: 600;
  text-align: right;
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.15);
  line-height: 55px;
}

.selected-data {
  padding: 20px 0 20px 20px;
}

.selected-data-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selected-circle {
  height: 12px;
  width: 12px;
  margin-right: 10px;
  border: 3px solid hsl(13deg 85% 57%);
  border-radius: 50%;
}

.selected-header {
  font-family: AvenirHeavy, serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #5a5858;
}

.help-text {
  color: #5a5858;
  font-family: AvenirRegular, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 30px;
  padding-top: 15px;
}

.data-right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 50px;
}

.selected {
  color: #5f5f5f;
}

.label-container:hover {
  color: #5f5f5f;
}

.actionLink {
  font-family: AvenirRegular, serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  color: var(--primary-color);
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton {
  min-width: 260px;
  font-size: 20px;
  font-weight: 400;
  font-family: AvenirRegular, serif;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  color: #5a5858;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 767px) {
  .section3-container {
    flex-direction: column;
  }

  .section3-container > div {
    width: 100%;
  }

  .selected-data,
  .data-right-container {
    padding-left: 0;
  }

  .label-container {
    text-align: left;
  }
}

.section4-container {
  display: flex;
  flex-direction: column;
  /* padding: 20px 70px; */
  padding: 20px 0;
}

.section4-container1 {
  padding: 20px 150px;
}

.section4_banner {
  background-image: url(/static/media/footer_banner.d9743bfa.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
}

/* .section4_banner>img {
    margin-left: -28px;
    width: calc(100% + 28px);
} */

.d-flex {
  display: flex;
}

.item-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-class {
  height: 50px;
  object-fit: contain;
}

.item-heading {
  font-family: AvenirHeavy, serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 6px;
}

.item-info {
  font-family: AvenirRegular, serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 290px;
  margin-top: 0;
}

.item-link {
  font-family: AvenirRegular, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary-color);
  cursor: pointer;
  padding-top: 30px;
  padding-bottom: 50px;
}

.item-link:hover {
  color: var(--secondary-color);
}

.section4-container-heading {
  font-family: AvenirRegular, serif;
  font-size: 60px;
  font-weight: 500;
  line-height: 73px;
  letter-spacing: 0em;
  padding: 50px 0 20px;
  /* margin-left: 50px; 
    margin-bottom: 25px;*/
  text-align: left;
  color: #5f5f5f;
}

.stay_in_touch > .item-container {
  align-items: center;
}

.stay_in_touch > .item-container .item-info {
  text-align: center;
}

/* .section-helper-text {
    text-align: center;
} */

@media (max-width: 767px) {
  .last-container .section-heading {
    font-size: 30px;
  }

  .last-container .section-helper-text {
    font-size: 16px;
  }

  .section-heading {
    font-size: 18px;
  }

  .stay_in_touch > .item-container .item-info,
  .section4-container .item-info,
  .help-text {
    font-size: 15px;
    margin-top: 8px;
  }

  .stay_in_touch .item-heading,
  .section4-container .item-heading {
    font-size: 30px;
  }

  .section4-container > div > div:nth-child(2) {
    margin: 40px 0;
  }

  .section4-container-heading {
    text-align: center;
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width: 420px) {
  .section-heading {
    font-size: 18px;
  }
}

.last-container {
  width: 100%;
  height: 611px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100vw 700px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

/* .section-helper-text {
    font-family: AvenirMedium, serif;
    font-size: 20px;
    color: white;
    padding: 10px 20px 25px;
} */

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
}

.getStarted:hover {
  /* box-shadow: #ffffff85 0 5px 7px 0; */
  background-color: var(--secondary-color);
  /* transform: scale(1.05); */
}

.getStarted {
  width: 200px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--primary-color);
  padding: 15px;
  border-radius: 7px;
}

.currencies {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.currencies-content {
  display: block;
  width: 100%;
  transform: translateX(100%);
  animation: move 30s linear infinite;
}

@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

.graphic-image {
  height: 60px;
  width: 60px;
  margin-bottom: 15px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.trade_to_earn_banner {
    display: flex;
    padding: 40px 120px 0 0;
    margin: 50px 0;
    flex-direction: column;
    height: auto;
    background-image: url(/static/media/TradeBannerBG.7be858bf.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.trade_left {
    width: 300px;
    height: 300px;
    margin-left: 20%;
    align-self: flex-end;
    background-image: url(/static/media/tradeEarnCoin.14c26657.png);
    background-size: auto;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-position-x: right;
}

.trade_right {
    padding-bottom: 35px;
}

.line_height_1 {
    line-height: 1;
}

.mega_heading {
    font-size: 80px;
    font-family: AvenirMedium;
}

.flex-direction-row {
    flex-direction: row;
}

.text-green {
    color: #8BE64B;
}

.tm_text {
    font-size: 15px;
    position: relative;
    bottom: 0;
}

.trade_percentage {
    font-family: AvenirHeavy;
    font-size: 130px;
    margin-top: 20px;
}

.earn_btn {
    font-size: 22px;
    min-height: 43px;
    margin: 35px auto 0;
}

.ant-btn.btn-success.earn_btn {
    max-width: 325px;
}

@media (max-width: 767px) {

    .trade_percentage {
        font-size: 55px !important;
    }

    .trade_percentage span {
        font-size: 30px !important;
    }

    .trade_left {
        width: 110px;
        height: 98px;
    }

    .ant-btn.btn-success.earn_btn {

        max-width: calc(100% - 30px);
    }



    .tm_text {
        font-size: 7px;
    }

    .mega_heading.text-white {
        font-size: 25px !important;
    }

    .mega_heading.trade_percentage .mega_heading.trade_percentage span {
        font-size: 30px !important;
    }

    .trade_to_earn_banner h1.text-white {
        font-size: 15px;
    }

    .trade_to_earn_banner {

        padding-right: 35px;
    }

    .trade_left {
        display: none;
    }

    .trade_right {
        margin-right: auto;
        margin-left: auto;
    }
}

.btn-danger {
  --bs-btn-bg: var(--primary-color);
  --bs-btn-hover-bg: var(--secondary-color);
  --bs-btn-active-bg: var(--primary-color);
}

.btn-danger {
  border-color: var(--primary-color);
}

.btn-danger:hover {
  border-color: var(--secondary-color) !important
    ;
}
.scan-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 180px;

  background-color: transparent;
  color: var(--body_color) !important;
}

.collapse {
  border: 1px;
  border-color: #006dff;
}
.font_50x {
  font-size: 50px;
}

.scan-Image {
  object-fit: contain;
  width: 653px;
}

.scan-heading {
  color: white;
  font-family: AvenirRegular, serif;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}

.helper-text {
  font-family: AvenirRegular, serif;
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 20px;
}

.section-usp {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 20px;

  padding-top: 120px;
  padding-bottom: 70px;
}

.font-big {
  color: white;
  font-size: 45px;
}

.main-header {
  background-color: #000;
  padding: 26px 90px;
  height: 90px;
  padding-top: 0;
  padding-bottom: 0;
}

.undo_icon {
  transform: scaleX(-1);
  margin-right: 12px;
}

/* style={{ fontSize: "24px", color: "#5f5f5f" }} */
.swap_icons {
  font-size: 20px;
  color: #5f5f5f;
}

.setting__icon {
  width: 25px;
  margin-right: 12px;
}

/* 
.from__icon {
    margin-bottom: 30px;
} */

.swap__coin__circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 109, 255, 0.1);
  border: none;
  background-image: url(/static/media/downArrow.8abbcc91.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.swap__coin__circle:hover {
  /* background: rgba(0, 109, 255, 0.25); */
  background-color: #006dff;
  color: #fff !important;
  border: none;
  background-image: url(/static/media/swapArrow.afdb3405.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.info__text {
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: flex-end;
}

.icon__label {
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  align-items: center;
}

.helper_text {
  color: #006dff;
  font-size: 14px;
  font-family: 'AvenirHeavy';
}

.lady_swap_icon {
  padding-bottom: 120px;
  margin-top: -190px;
}

.question_iocn {
  color: #006dff;
  font-size: 20px;
}

.coming_soon {
  padding-top: 20px;
}

.coming_soon .page_name {
  text-transform: capitalize;
  /* padding-right: 10px; */
}

.ant-modal-close {
  width: auto;
}
@media (max-width: 1400px) {
  .scan-container {
    flex-direction: column;
    align-items: center !important;
  }
}
@media (max-width: 767px) {
  .section-usp {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 50px;
  }

  .center-Image {
    height: 59px;
    margin-top: -58px;
  }

  .font-big {
    font-size: 18px;
  }

  .big_trade_font {
    font-size: 55px;
  }

  .big_trade_font sub {
    font-size: 10px !important;
  }

  .trade_to_earn_link {
    padding-bottom: 50px;
    display: block;
    padding-top: 100px;
  }

  .section-helper-text {
    font-size: 12px;
  }

  .cut_button {
    max-width: 150px;
  }

  .font_50x {
    font-size: 28px;
  }

  .logo {
    padding-left: 0.75rem !important;
  }

  .home-container {
    height: 800px;
  }

  .selected-header,
  .item-heading,
  .label-container {
    font-family: AvenirRegular, serif;
  }

  .navbar-toggler,
  .navbar-toggler:focus {
    border: none;
    box-shadow: none;
  }

  /* .chart_buy {
    display: none;
  } */

  .buy_sell_bg {
    background-size: contain;
  }

  .accounts_container .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding-left: 12px !important;
  }

  .accounts_container .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 20px !important;
  }

  .accounts_container .ant-tabs-tab {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
  /* .big_trade_font {
        font-size: 28px;
}

.font-big {
    font-size: 28px;
}

*/
}

.search_token {
    border: none;
    border-bottom: 1px solid rgba(0, 109, 255, 0.5);
    margin: 20px;
    width: calc(100% - 40px);
}

.search_token:hover,
.search_token:focus {
    border-bottom: 1px solid rgba(0, 109, 255, 1);
    box-shadow: none;

}

/* style={{ display: "flex", alignItems: "center", marginBottom: 30, padding: 0 }} */
.token {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: auto;
    padding-top: 15px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 109, 255, 0.25);
}

.token:hover {
    background: rgba(217, 217, 217, 0.4);
    border-bottom: 1px solid rgba(0, 109, 255, 0.25);
}

/* style={{ paddingLeft: 10, display: "flex", flexDirection: "column", alignItems: "baseline" }} */
.token__data {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

/* style={{ fontSize: "30px", color: "#5F5F5F", padding: "0", lineHeight: 1, display: "block" }} */
.token__data__title {
    font-size: 23px;
    color: #5F5F5F;
    line-height: 1;
    display: "block";
    text-transform: uppercase;
}

/* style={{ "color": "#5F5F5F", paddingLeft: 3 }} */
.token__data__subtitle {
    color: #5F5F5F;
    padding-left: 3px;
    font-size: 15px;
}

.common_token_wrapper {
    padding: 0 17px 20px;
}

.common_token_heading {
    padding-bottom: 20px;
}

.common__token {
    padding: 10px 8px !important;
    border: none;
    border-right: 1px solid rgba(0, 109, 255, 0.2);
    height: auto;
}

.common__token:hover {
    border-right: 1px solid rgba(0, 109, 255, 0.2);
}

.common__token:hover .common__token__title {
    color: #333;
}

.common_tokens> :first-child {
    padding-left: 0;
}

.common_tokens> :last-child {
    padding-right: 0;
    border: 0;
}

.common__token__title {
    border: none;
    color: #5F5F5F;
    font-size: 23px;
    line-height: 1.2;
    padding-left: 4px;
}

.ant-btn.common__token[disabled] {
    opacity: 0.3;
}
h1 {
    margin-bottom: 0;
    font-size: 30px;
    color: #5f5f5f;
}

.card_title {

    font-size: 40px;
    color: #5f5f5f;
    line-height: 1.2;
}

.helper_text {
    color: #5f5f5f;
    font-size: 14px;
    font-family: AvenirHeavy;
}

.card_body {
    padding: 20px;

}

.swap__coin {
    padding: 23px 0 23px 0;
}

.footer {
    padding: 0 20px 20px 20px;
    color: #5f5f5f
}

.footer.ant-btn-primary:hover, .footer .ant-btn-primary:focus {
    background-color: #00387d;
    border-color: #00387d;
}

.footer_body {
    padding-top: 10px;
}


.disable_icon {
    opacity: 0.5;
}
.card.card-s {
    min-height:330px;
    padding:19px;
}
    




































































.card.bs_container,
.bs_main > .card {
  border: 1px solid var(--border-color);
}

.card.bs_container {
  min-height: 500px;
}

.padding-b-1x {
  padding-bottom: 10px;
}

.margin-t-1_5x {
  margin-top: 15px;
}

.bs_container {
  background: var(--body_background);
  color: var(--body_color);
  /* background: #ffffff; */

  border: 1px solid var(--border-color);
  border-radius: 0px;
  max-width: 423px;
}

.bs_token_left {
  flex: 1 1;
}

.ant-select-item-group {
  padding: 0;
}

.custom-optgroup-label {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--body_color);
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  padding-block: 10px;
  width: 100%;
  padding-block: 15px;
}

.bs_curreny_left {
  flex: 1 1;
  text-align: center;
  font-size: 100px;
  padding: 6px 32px 20px 0;
  display: flex;
  flex: 1 1;
  align-items: baseline;
  justify-content: center;
}

.bs_curreny_left > input {
  font-size: 100px;
  height: 100px;
  text-align: center;
  border: 0;
  color: #5f5f5f;
}

.bs_container_main {
  padding: 44px 41px 44px 44px;
}

button {
  width: 100%;
  background: var(--primary-color);
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  border: none;
  height: 55px;
  cursor: pointer;
}

button:hover,
button:focus,
button:active {
  background: var(--secondary-color);
}

.bs_container_header {
  margin: 0;
  font-size: 30px;
  padding: 26px;
  color: var(--body_color);
  border-bottom: 1px solid var(--border-color);
}

.d-flex {
  display: flex;
}

.bs_footer_action {
  padding: 26px;
  border-top: 1px solid var(--border-color);
}

.bs_footer_action button {
  color: #ffffff;
  /* background: rgba(95, 95, 95, 0.2); */
  background-color: var(--primary-color);
  border-radius: 0px;
  height: 55px;
}

.bs_footer_action button:hover,
.bs_footer_action button:focus,
.bs_footer_action button:active {
  background-color: var(--secondary-color);
  outline: none;
}

.bs_footer_action button.disable_icon:hover,
.bs_footer_action button.disable_icon:focus,
.bs_footer_action button.disable_icon:active {
  background-color: var(--primary-color);
}

.bs_token_num {
  font-size: 20px;
  color: var(--body_color);
}

.bs_token {
  border-top: 1px solid var(--border-color);

  padding: 20px;
}

.token_grey {
  color: var(--light-gr-color);
  padding-left: 5px;
}

.justify-between {
  justify-content: space-between;
}

.bs_purchase {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  color: #5f5f5f;
  border: 1px solid var(--border-color);
  border-radius: 0px;
  margin: auto;
}

.bs_purchase .ant-space {
  padding: 8px 16px;
}

.bs_curreny {
  font-size: 100px;
  color: rgba(18, 18, 18, 0.5);
}

.bs_currency_symbol {
  font-size: 50px;
}

.bs_container_header.border-b-0 {
  border-bottom: 0;
}

input.search {
  border: 1px solid var(--border-color);
  border-radius: 0px;
  height: 47px;
  font-size: 17px;
  padding: 14px 15px 15px 48px;
  color: #5f5f5f;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 40%;
}

.bs_container_select_main {
  padding: 20px;
}

.position-relative {
  position: relative;
}

input + .search_icon {
  position: absolute;
  left: 35px;
  top: 33px;
  border-right: 1px solid var(--border-color);
  padding-right: 8px;
}

input::-webkit-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
}

.common_token_subTitle {
  color: rgb(95 95 95 / 20%);
  font-size: 25px;
  line-height: 1.2;
}

.bs_token_container .common_token_title {
  font-size: 25px;
  padding: 0 10px;
}

.bs_token_container {
  padding: 12px 24px;
}

.common_tokens > .bs_token_container:first-child {
  padding-left: 24px;
}

.common__token.bs_token_container {
  border-right: 0;
}

.bs_container_create_main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 285px;
  padding-top: 40px;
}

.bs_container_create_main div {
  font-size: 25px;
  color: var(--body_color);
  padding: 25px 0;
}

.bs_container_create_footer {
  padding: 25px;
}

button.ant-btn-link.btn_link span {
  color: var(--primary-color);
  font-size: 20px;
  height: 39px;
  padding-top: 16px;
}

button.ant-btn-link.btn_link span:hover {
  color: var(--secondary-color);
}

.left_arrow {
  padding-right: 8px;
  cursor: pointer;
}

.bs_container.bs_form {
  padding: 32px 16px 25px 16px;
}

/* .form_element input:not([type="checkbox"]) {
    background: #FFFFFF;
   border: 1px solid #D7D7D7;
    border-radius: 3px;
    height: 51px;
    width: 100%;
    font-size: 15px;
    padding: 0 13px;
} */

.input_height {
  height: 50px;
}

.bs_main .input_icon {
  position: absolute;
  right: 21px;
  transform: translate(50%, 50%);
}

.bs_main input[type='text'],
.ant-input-password.ant-input-affix-wrapper {
  border-color: var(--border-color);
}

.terms_conditions {
  color: #2e2d2d;
  font-size: 15px;
  padding-left: 9px;
  text-align: center;
}

.text_link {
  color: var(--primary-color);
}

.text_link:hover {
  color: var(--secondary-color);
}

input[type='checkbox'] {
  height: 38px;
  width: 28px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}

.padding-tb-2x {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-tb-3x {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-tb-4x {
  padding-top: 40px;
  padding-bottom: 40px;
}

.terms_conditions_container {
  padding-top: 10px;
  padding-bottom: 30px;
}

.justify-center {
  justify-content: center;
}

.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.verfication_text {
  width: 280px;
  font-size: 15px;
  color: var(--body_color);
}

.otp_container input {
  width: 50px;
  height: 65.48px;
  background: var(--disabled-btn);
  border: 1px solid var(--border-color);
  border-radius: 0px;
  font-size: 30px;
  text-align: center;
}

.ssn_container input {
  width: 160px;
  height: 28px;
  background: var(--disabled-btn);
  border: 1px solid var(--border-color);
  border-radius: 0px;
  font-size: 30px;
  text-align: center;
}

.send_code button {
  /* background: rgba(95, 95, 95, 0.2); */
  border-radius: 0px;
}

.bs_main label {
  font-size: 15px;
  color: var(--body_color);
}

.phone_container .ant-input-group-lg .ant-select-single .ant-select-selector,
.phone_container input {
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  height: 47px;
}

.border-0 .ant-select-selector {
  border: 0 !important;
}

.border-0.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-size: 19px;
  /* color: rgba(18, 18, 18, 0.2); */
  color: #5f5f5f;
}

.phone_container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.phone_container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 47px;
}

.phone_container_right {
  flex: 1 1;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  display: none;
}

.ant-dropdown-menu-item {
  padding: 10px 20px;
  font-size: 15px;
}

.ant-dropdown-menu-item:hover {
  color: var(--primary-color);
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover {
  color: #f6603653;
}

.scan-container.bs_main,
.bs_wallet {
  padding-top: 180px;
}

.default-link {
  font-size: 15px;
  line-height: 20px;
  color: var(--body_color);
}

.w-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.default-link:hover {
  color: #5f5f5f;
}

.border-always {
  border-color: var(--border-color);
  border: 1px;
}

.border-default {
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 6px 15px;
}

.border-b-1x {
  border-bottom: 1px solid var(--border-color);
}

.margin-b-2x {
  margin-bottom: 20px;
}

.card_s.card {
  min-height: 294px;
}

.card_s .verfication_text {
  font-size: 17px;
}

.sms_verfication {
  position: relative;
  right: -104%;
}

.border_separator {
  border: 1px solid #d7d7d7;
  padding: 20px;
  width: 100%;
  height: 2px;
}

.text-underline {
  text-decoration: underline;
}

.text-underline:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.text-heavy {
  font-family: AvenirHeavy;
}

.ant-input-affix-wrapper {
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  height: 51px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 0;
}

button.ant-btn-primary [disabled] {
  background-color: #5f5f5f;
  height: 55px;
  border-color: '#5F5F5F';
  color: '#fff';
  font-size: 20px;
  border-radius: 0px;
}

.bs_main .ant-btn-primary,
.bs_main .ant-btn-primary:active,
.bs_main .ant-btn-primary:focus {
  height: 55px;
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: #fff;
  font-size: 20px;
  border-radius: 0px;
}
.bs_main .ant-btn-primary:hover {
  height: 55px;
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;
  color: #fff;
  font-size: 20px;
  border-radius: 0px;
}
.bs_main .ant-btn-primary[disabled],
.bs_main .ant-btn-primary[disabled]:hover {
  background: rgba(95, 95, 95, 0.2);
  border-color: rgba(95, 95, 95, 0.2);
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 0 19px;
  padding: 20px 10px;
  font-size: 30px;
  color: var(--body_color);
  height: 80px;
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab:hover {
  color: var(--primary-color);
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
  margin-left: 20px;
  margin-right: 20px;
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--primary-color);
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.bs_main > .card {
  min-height: auto;
  /* padding-bottom: 20px; */
}

.ant-btn-dangerous.ant-btn-primary {
  background-color: var(--primary-color) !important ;
  border-color: var(--primary-color) !important ;
}

.ant-btn-dangerous.ant-btn-primary:hover {
  background-color: var(--secondary-color) !important ;
  border-color: var(--secondary-color) !important ;
}

.bs_link,
a.bs_link:hover {
  color: var(--primary-color) !important;
}

.padding-lr-0 {
  padding-left: 0;
  padding-right: 0;
}

.swap_Arrow_icon {
  position: absolute;
  right: 4px;
  top: 39%;
  opacity: 0.7;
}

.swap_Arrow_icon:hover {
  opacity: 1;
}

.bs_main .ant-btn-primary:hover {
  background-color: var(--secondary-color);
}

.bs_main .card__header {
  border-color: #d7d7d7;
  min-height: 87px;
}

.bs_main .ant-btn-primary:hover,
.bs_main .ant-btn-primary:focus,
.bs_main .footer.ant-btn-primary:hover,
.bs_main .footer .ant-btn-primary:focus {
  border-color: #d7d7d7;
  background-color: var(--secondary-color);
}

.bs_curreny_left input:hover,
.bs_curreny_left input:focus,
.bs_curreny_left input:active {
  border: 1px solid #d7d7d7;
  border: none;
  outline: none;
}

.bs_curreny_left > input.input_currency {
  max-width: 275px;
  font-size: 60px;
  font-size: 1.1ch;
  background-color: var(--body_background);
  color: var(--body_color);
}

.error_message {
  background: var(--err-clr);
  color: var(--body_color);
  border-radius: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 1rem;
  font-size: 13px;
  width: -moz-fit-content;
  width: fit-content;
}

.top_heading {
  font-size: 48px;
  font-family: AvenirLight;
}

.buy_sell_bg {
  background-image: url(/static/media/ladyBuyGoldImage.0aa80612.png);
  height: 650px;
  background-repeat: no-repeat;
  background-position: top center;
}

.responsive_container .card,
.responsive_container .bs_container {
  margin: 19px;
}

.index_link_info {
  background-color: var(--body_background);
}

.ant-form-item-required {
  color: var(--body_color) !important;
}

.notif {
  display: flex;
  justify-content: center;
  background-color: #000;
  color: #e0e0e0;
  font-size: 17px;
  font-weight: bold;
  width: 100%;
  position: fixed;
  top: 180px;
  z-index: 999;
  padding-block: 7px;
}

@media (max-width: 767px) {
  .responsive_container .card,
  .responsive_container .bs_container,
  .responsive_container.card.bs_container,
  .bs_main > .responsive_container.card {
    min-width: 100%;
    max-width: 100%;
    margin-inline: 0;
  }

  .bs_wallet .card,
  .bs_wallet .border-b-1x {
    min-width: 95%;
    border-color: #d7d7d7;
  }

  .bs_wallet .ant-input-prefix,
  .bs_wallet .orange_input.ant-input-affix-wrapper,
  .bs_wallet .orange_input.ant-input-affix-wrapper:hover,
  .bs_wallet .orange_input input,
  .orange_input input:hover {
    border-color: #d7d7d7 !important;
  }

  .bs_wallet .padding-2x {
    padding: 0;
  }

  .bs_main label,
  .placeholder_info {
    color: #5f5f5f !important;
  }

  .responsive_container .card,
  .responsive_container.card,
  .responsive_container.card.bs_container,
  .bs_main > .responsive_container.card {
    border: none;
  }

  .responsive_container .bs_container.bs_form {
    padding: 0 16px;
    /* margin-inline: auto !important; */
  }

  .responsive_container .top_heading {
    font-size: 35px;
  }

  .responsive_container h1 {
    font-size: 25px;
  }

  .responsive_container .secure-steps .font_20x {
    font-size: 13px;
  }

  .responsive_container .secure-steps .d-flex img {
    width: 40px;
    height: 40px;
  }

  .two-factor-text {
    font-size: 15px;
  }

  .verfication_text {
    font-size: 11px;
  }

  .verfication_text.email_verification {
    font-size: 13px;
  }

  .scan-container.bs_main {
    padding-top: 160px;
  }

  .bs_container.card {
    padding: 0 1rem;
  }

  .card.bs_container,
  .bs_main > .card {
    border: none;
  }

  .bs_curreny_left .font_60x {
    font-size: 60px;
  }

  .with-graph {
    flex-direction: column;
    height: max-content;
    min-height: 500px !important;
  }

  .notif {
    font-size: 12px;
    width: 100vw;
    top: 146px;
    padding-inline: 10px;
    text-align: center;
  }
}

.bs_main .hive-btn {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
  font-size: 20px;
  height: 55px;
  color: #fff;
  border-radius: 0;
}

.bs_main .hive-btn:hover,
.bs_main .hive-btn:focus,
.bs_main .hive-btn:active {
  background-color: #ffd000 !important;
  border-color: #ffd000 !important;
}

.blk_yl_link {
  color: var(--body_color);
}

.blk_yl_link:hover {
  color: #ffb300;
  cursor: pointer;
}

.hive_link {
  color: #ffb300;
}
.login_links {
  display: block;
  width: 100%;
  height: 55px;
  background: #ffb300;
  color: #fff;
  text-align: center;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.2s linear;
}
.login_links:hover {
  background: #ffd000;
  color: #fff;
}

.hive_link:hover {
  color: #ffd000;
  cursor: pointer;
}

.walls {
  transition: transform 330ms ease-in-out;
}

.walls:hover {
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

.bs_container.medium_card {
    min-width: 700px;
}

.medium_card .light_button, .light_button, .light_button:hover, .light_button:focus, .light_button:active {
    padding: 6px 15px 10px 40px !important;
    background-repeat: no-repeat;
    background-position: left 10px center;
}

.deposit_btn,
.deposit_btn:hover,
.deposit_btn:focus,
.deposit_btn:active {
    background-image: url(/static/media/depositIcon.63f93c64.svg);
}

.deposit_btn{
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.withdraw_btn{
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.deposit_btn:hover,
.deposit_btn:focus,
.deposit_btn:active
{
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.withdraw_btn,
.withdraw_btn:hover,
.withdraw_btn:focus,
.withdraw_btn:active {
    background-image: var(--background-image);
    background-color: var(--body_background);
    background-size: 17px;
    color: var(--body_color) !important;
}

.send_btn,
.send_btn:hover,
.send_btn:focus,
.send_btn:active {
    background-image: url(/static/media/rocketIcon.8b2673ad.svg);
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.sell_screens .bs_token {
    padding: 12px 20px;
}

/* 
.sell_screens .bs_footer_action button {
    background-color: #00A151;
    border-color: #00A151;
} */
/* 
.sell_screens .bs_footer_action button:hover,
.sell_screens .bs_footer_action button:focus,
.sell_screens .bs_footer_action button:active {
    background-color: #026333 !important;
} */
/* 
.sell_screens .bs_footer_action button.disable_icon:hover,
.sell_screens .bs_footer_action button.disable_icon:focus,
.sell_screens .bs_footer_action button.disable_icon:active {
    background-color: #00A151 !important;
}

.sell_screens button.sell_btn {
    background-color: #00A151;
    border-color: #00A151;
} */

.font_w_800 {
    font-weight: 800;
}

.margin-t-3x {
    margin-top: 30px;
}


.margin-t-2x {
    margin-top: 20px
}

.margin-t-auto {
    margin-top: auto;
}

.buy_purchase_modal .ant-modal-title {
    font-size: 0;
    /* background-image: url("../../assets/arts/indexGreyLogoIcon.svg"); */

    background-image: url(/static/media/indexBlueLogoIcon.3671e474.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: auto;
    /* background-color: var(--body_background) !important; */
}

.ant-modal-header{
    background-color: var(--body_background) !important;
    border-color: var(--border-color);
}

.ant-select-dropdown, .ant-select-selector, .ant-select {
    background-color: var(--body_background) !important;
}


.bs_token_left:hover .ant-select-dropdown:not(:hover) 
{
    background-color: var(--body_background) !important;

}
.ant-select-item-option-selected {
    background-color: var(--disabled-btn) !important;
    color: var(--body_color); 
}


.common__token {
    color: var(--body_color);
    background-color: var(--body_background);
    border-color: var(--border-color);
  }
  
  .common__token:hover {
    color: var(--body_color);
    background-color: var(--disabled-btn);
}

.custom-modal .ant-modal-close-x {
  color: var(--body_color); /* Your desired color */
}
  
.custom-modal .ant-modal-footer{
    color: var(--body_color);
    background-color: var(--body_background);  
    border-color: var(--border-color);
}
.ant-btn-primary:hover {
  background: rgba(214, 1, 1, 0.87)!important;
}

.custom-modal .ant-modal-close-x {
  color: var(--body_color); /* Your desired color */
}
  
.custom-modal .ant-modal-footer{
    color: var(--body_color);
    background-color: var(--body_background);  
    border-color: var(--border-color);
}
.bs_container.medium_card {
  min-width: 700px;
}

.custom-select .ant-select-dropdown-menu-item {
  color: white; /* Set your desired text color */
}

.custom-select .ant-select-dropdown-menu-item:hover {
  background-color: var(--dropdown-hover); /* Set your desired hover background color */
  color: white; /* Set your desired hover text color */
}

.common__token {
  color: var(--body_color);
  background-color: var(--body_background);
  border-color: var(--border-color);
}

.common__token:hover {
  color: var(--body_color);
  background-color: var(--disabled-btn);
}

.medium_card .light_button {
  padding: 6px 15px 10px 40px;
  background-repeat: no-repeat;
  background-position: left 10px center;
}

.bs_wallet_top {
  background: var(--primary-color);

  max-width: calc(100% - 160px);
  margin: 0 auto 20px;
  padding: 20px;
  border-radius: 5px;
}

.margin-l-0_5x {
  margin-left: 5px;
}

.margin-r-0_5x {
  margin-right: 5px;
}

.color_white {
  color: #fff;
}

.large_card {
  margin: 0 auto;
  max-width: calc(100% - 160px);
  /* margin-bottom: 300px; */
  padding-top: 0;
}

.full_width_tabs {
  width: calc(100% - 160px);
}

/* .custom_table tr > td.ant-table-cell:nth-child(3) {
  text-align: right;
} */

.orange_input.ant-input-affix-wrapper,
.orange_input.ant-input-affix-wrapper:hover,
.orange_input input,
.orange_input input:hover {
  border-color: var(--primary-color) !important;
}

.orange_input.ant-input-affix-wrapper {
  height: 30px;
}

.bs_wallet .ant-input-prefix {
  /* border-color: rgba(246, 96, 54, 0.3); */
  border-color: var(--primary-color) !important;
}

.bs_wallet .ant-tabs-extra-content {
  margin-right: 20px;
  margin-bottom: 10px;
}

.bs_wallet .ant-tabs-nav-wrap {
  padding-left: 20px;
}

.bs_wallet_top_banner {
  /* background-position-y: bottom 0; */
  /* padding-bottom: 320px; */
  /* background-image: url('../../assets/coming_soon.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover; */
  width: calc(100% - 50px);
}

.bs_wallet_buttons {
  position: absolute;
  right: 0;
  top: 20px;
}

.custom_table 
.ant-table-tbody 
.ant-table-cell
{
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-thead > tr > th {
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-thead > tr > th:hover {
  background-color: var(--disabled-btn);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-cell-row-hover{
  background-color: var(--disabled-btn) !important;
  color: var(--body_color);
  border-color: var(--border-color);
}

.staking-sty{
  color: var(--primary-color) !important;
}

.staking-btn:hover{
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.stake-btn{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  border-radius: 2px;
  color: #282828;
  height: 40px;
  text-transform: none;
  font-size: 16px;
  font-weight: 100 !important;
}

.stake-btn:hover, 
.stake-btn:active{
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: #282828;
}

.stake-outlined-btn{
  border-color: var(--primary-color) !important;
  color: var(--primary-color);
  background-color: var(--body_background);
  border-radius: 2px;
  height: 44px;
  text-transform: none;
  font-size: 16px;
  font-weight: 100 !important;
}

.stake-outlined-btn:hover{
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
  background-color: var(--body_background);
}

.staking-toggle .MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-left: 1px solid var(--border-color) !important;
}

.staking-toggle .MuiToggleButtonGroup-grouped:not(:first-of-type):focus,
.staking-toggle .MuiToggleButtonGroup-grouped:not(:first-of-type):active
{
  border-left: 1px solid var(--primary-color) !important;
}

@media (max-width: 767px) {
  .bs_wallet_top {
    max-width: 90%;
    display: block !important;
  }
  .bs_wallet_top div:nth-child(2) a {
    margin-left: 0;
  }
  .bs_wallet_buttons {
    position: inherit;
  }
  .bs_wallet_table table th,
  .bs_wallet_table table td {
    padding: 16px 8px;
  }
  h1 {
    font-size: 25px;
  }
}


.LineGraph_container__3aWV2 {
  margin: auto;
  max-width: 1200px;
}

.LineGraph_graph__10x0G {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.LineGraph_graph__10x0G h3 {
  text-align: center;
}

.LineGraph_graphBackground__zeJH7 {
  background-color: var(--body_background);
  width: auto;
  height: auto;
}

.LineGraph_xscroll__1jCyk {
  overflow: scroll;
}

.LineGraph_button__SKbwo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #112d3f;
  color: white;
  width: 85px;
  height: 23px;
  margin-right: 3rem;
  cursor: pointer;
  border: none;
  margin: 0.4rem;
  border-radius: 5px;
}

.LineGraph_button__SKbwo:hover {
  background-color: #236086;
}

/* .Chart_inner {
  padding: 10px 0;
} */

.LineGraph_chart_inner_right__2C7SO .LineGraph_chart_dynamic__ytaCq .LineGraph_ant-segmented-item-label__2og0a {
  float: right;
  width: 65px;
}

.LineGraph_chart_inner_right__2C7SO .LineGraph_chart_dynamic__ytaCq label {
  padding-bottom: 100;
}

/* .chart_header {
    display:flex;
    align-items:center;
} */
.LineGraph_chart_header__2d_T0 h1 {
  margin-bottom: 0;
  font-size: 30px;
  color: #5f5f5f;
  line-height: 1;
}

.LineGraph_chart_title__URNgP {
  padding: 0 7px;
}

.LineGraph_arrow_container__1G5ss div {
  line-height: 0;
  padding-bottom: 2px;
}

/* .Chart_inner {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 12px 0;
  align-items: baseline; */
  /* padding-right: 76px; */
/* } */

/* .chart_inner_left_top {
  display:flex;
} */

.LineGraph_chart_inner_middle__3QvK1 {
  color: var(--primary-color);
  font-size: 20px;
}

.LineGraph_chart_inner_right__2C7SO .LineGraph_ant-segmented__9UlEk {
  /* background-color: rgba(129, 129, 129, 0.1); */
  font-size: 15px;
  /* color: #5f5f5f; */
  padding: 0;
  border-radius: 5px;
}

.LineGraph_chart_inner_right__2C7SO .LineGraph_ant-segmented-item-label__2og0a {
  width: 65px;
  font-size: 15px;
  min-height: 37px;
  line-height: 37px;
}

.LineGraph_chart_inner_right__2C7SO .LineGraph_chart_dynamic__ytaCq label {
  padding-bottom: 0;
}

.LineGraph_chart_inner_right__2C7SO .LineGraph_chart_dynamic__ytaCq .LineGraph_ant-segmented-item-label__2og0a {
  width: 65px;
}

.LineGraph_bs_main__2jkvx .LineGraph_chart_inner_right__2C7SO .LineGraph_ant-segmented-item-selected__2yFnQ {
  /* background: #006DFF;
  color:#fff; */
  background: var(--primary-color);
  /* color: #000; */
  color: var(--dark-text);
  background-color: transparent;
  border-radius: 5px;
}

.LineGraph_time__3BGL9 {
  /* display:flex;
  justify-content: space-between; */
  padding: 10px 15px;
}

.LineGraph_time__3BGL9 div {
  color: #5f5f5f;
  font-size: 20px;
}

.LineGraph_tooltip__2l0OL {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.Graph_error__2jGJ1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10rem;
    padding-right: 6rem;
  }
  h5 {
    width: 470px;
    border: 1px red solid;
    background-color: rgb(214, 77, 77);
    text-align: center;
    font-size: 1rem;
  }
  
  @media only screen and (max-width: 500px) {
    .Graph_error__2jGJ1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 7rem;
      padding-right: 0rem;
    }
    h5 {
      width: 170px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    .Graph_error__2jGJ1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 15rem;
      padding-right: 0rem;
    }
    h5 {
      width: 170px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .Graph_error__2jGJ1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 20rem;
      padding-right: 0rem;
    }
    h5 {
      width: 370px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  
.main-box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.second-box{
    position: relative;
    top: 28px;
    width: 488px;
    background-color: var(--body_background);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.third-box{
    position: relative;
    top: 28px;
    width: 633px;
    background-color: var(--body_background);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-box {
    color: var(--dark_text);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
    font-size: 30px;
    font-weight: 600;
    /* font-family: "Inter"; */
}

.text-box-2 {
    color: var(--dark_text);
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
    font-size: 20px;
    /* font-family: "Inter"; */
    width: 80%;
    text-align: center;
}

.text-box-stake {
    color: var(--dark_text);
    /* padding-left: 1.5rem; */
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
    font-size: 20px;
    /* font-family: "Inter"; */
    width: 90%;
    text-align: center;
}

.button-box {
    color: var(--dark_text);
    margin-left: 1.5rem;
    margin-top: 1.2rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    /* font-family: "Inter"; */
    font-size: 13px;
}

.button-box-2 {
    color: var(--dark_text);
    /* margin-left: 1.5rem; */
    margin-top: 1.2rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    /* font-family: "Inter"; */
    font-size: 13px;
}

.button-btn {
    background-color: #FFB300;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    /* border-radius: 0.125rem;  */
    border: 0;
    border-radius: 2px;
    color: var(--dark_text);
}

.button-btn:hover {
    background-color: #FFD000;
}

.close-button {
    position: relative;
    top: -13%;
    /* right: 35%; */
    left: 31%;
    cursor: pointer;
    font-size: 1.25rem;
    color: grey;
    z-index: 1000;
}

.close-button-stake {
    position: relative;
    top: -22%;
    /* right: 35%; */
    left: 47%;
    cursor: pointer;
    font-size: 1.25rem;
    color: var(--dark_text);
    z-index: 1000;
}
/* #root {
    display: flex;
    align-items: center;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  } */

.stripeForm {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin: auto;
}

.ant-modal-close {
  width: auto;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripeButton {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripeButton:hover {
  filter: contrast(115%);
}

.stripeButton:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 767px) {

  .stripeForm {
    min-width: 100%;
    padding: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .check-out form {
    width: 80vw;
    min-width: initial;
  }
}
.main-pay-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.pay-box {
  position: relative;
  padding-inline: 50px;
  top: 28px;
  width: 788px;
  background-color: var(--body_background);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.pay-text-box {
  color: var(--dark_text);
  padding-left: 1.5rem;
  /* padding-bottom: 1.5rem; */
  padding-top: 1.5rem;
  font-size: 25px;
  /* font-family: "Inter"; */
  width: 80%;
  text-align: left;
}

.pay-button-box {
  color: var(--dark_text);
  margin-left: 1.5rem;
  margin-top: 1.2rem;
  margin-bottom: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  /* font-family: "Inter"; */
  font-size: 13px;
}

.pay-button-btn {
  width: 25%;
  background-color: #ffb300;
  /* padding-left: 4.2rem; */
  /* padding-right: 4.2rem; */
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  /* border-radius: 0.125rem;  */
  border: 0;
  border-radius: 2px;
  color: var(--dark_text);
  height: 40px;
}

.button-btn:hover {
  background-color: #ffd000;
}

.close-button-pay {
  position: relative;
  top: -13%;
  /* right: 35%; */
  right: -30%;
  cursor: pointer;
  font-size: 1.25rem;
  color: var(--dark_text);
  z-index: 1000;
}

.pay-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pay-label {
  margin-bottom: 10px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.radio-button {
  margin-left: 10px;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #ffb300 !important; /* Change this color to your preferred color */
}
.label-content {
  display: flex;
  align-items: center;
}

.pay-image {
  width: 120px;
  /* height: 30px; */
  margin-right: 30px;
}

.pay-description {
  margin: 0;
  font-size: 13px;
}

.selected {
  border: 2px solid var(--secondary-color); /* Adjust the color as needed */
  border-radius: 4px;
  padding: 5px;
  width: 120px;
}

@media (max-width: 767px) {
  .pay-image {
    width: 60px;
    margin-right: 10px;
  }

  .pay-box {
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
  }

  .pay-text-box {
    padding-left: 1rem;
    padding-bottom: 0;
    padding-top: 1rem;
  }

  .pay-button-btn {
    font-size: 15px;
    width: 25%;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    border: 0;
    border-radius: 2px;
  }
}

.coll {
  border: 1px solid var(--border-color) !important;
  border-top: none !important;
  border-top: none;
  margin-bottom: 100;
  color: #5f5f5f;
  width: 1340px;
}
.coll .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-radius: 0 !important;
  color: var(--body_color);
  border-top: none;
}
.coll .ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
  color: var(--primary-color);
}

/* .coll .ant-collapse {
  border: 1px solid var(--border-color) !important;
} */

.etf_table .MuiTableContainer-root {
    background-color: var(--body_background) !important;
  }

  .etf_table .MuiTable-root{
    border: 1px solid var(--table-border);
    border-bottom: none;
  }

  .etf_table .MuiTableBody-root .MuiTableRow-root:nth-child(odd){
    background-color: var(--table-light) !important;
  }
  
  .etf_table .MuiTable-root .MuiTableRow-head .MuiTableCell-root{
    font-weight: bold !important;
  }

  .etf_table .MuiTable-root .MuiTableRow-head,
  .etf_table .MuiTableBody-root .MuiTableRow-root:nth-child(even){
    background-color: var(--body_background) !important;
  }

  .etf_table .MuiTableCell-root {
    color: var(--body_color) !important;
    border-bottom: none !important;
    min-width: 250px;
  }

  .etf_table .MuiSvgIcon-root{
    vertical-align: baseline !important;
  }

  .etf_table {
    overflow-x: auto;
  }
  
  .etf_table::-webkit-scrollbar {
    width: 12px;
  }

  /* .etf_table::-webkit-scrollbar-button {
    display: block;
    width: 12px;
    height: 12px;
  } */
  
  .etf_table::-webkit-scrollbar-thumb {
    background-color: rgba(var(--scroller), 0.5);
    border-color: var(--table-border) !important;
  }
  
  .etf_table::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--scroller), 0.7);
  }
  
  .etf_table::-webkit-scrollbar-track {
    /* background-color: var(--table-light); */
    background-color: var(--body_background);

  }
  
.ext-tabs .ant-tabs-ink-bar {
  background-color:  var(--body_color) !important;
}

.ext-tabs .MuiTabs-indicator{
  background-color:  var(--body_color) ;
  height: 0.7px;
  transform: translateX(20%); 
  width:70px !important
}

.ext-tabs{
  background: var(--main-body);
  color: var(--body_color);
  width: 100vw;
  z-index: 999;
  height: 90px;
  padding-top:15px;
  padding-bottom:100px;
  position: fixed;
}

.other-tabs{
  background: #FFB300;
  color: #000;
  width: 100vw;
  z-index: 999;
  height: 90px;
  padding-top:5px;
  padding-bottom:85px;
  position: fixed;
}

.other-tabs .MuiTabs-indicator{
  background-color:  #000 !important ;
  height: 0.7px;
  transform: translateX(50%); 
  width:50px !important
}

.tab-format{
  width: 100px !important;
  text-transform: none !important;
  color: inherit !important;
  font-size: 10px !important;
  padding: 0 !important;
  min-height: 70px !important;
}

.tab-format:hover, 
.tab-format:focus, 
.tab-format:active 
{
  background: transparent;
}

@media (max-width: 767px) {

  .tab-format{
    width: 40px !important;
  }
}
.nav_container {
    padding: 90px 90px 0 55px;
}

.help_content_page {
    padding: 53px 0 180px 60px;
    border-left: 1px solid rgba(0, 109, 255, 0.2);
    width: 100%;
}

.nav_main_header {
    padding-bottom: 7px;
}

h1 {
    margin: 0;
    font-size: 30px;
    color: #5f5f5f;
}

.nav_container p {
    font-size: 20px;
    color: #5f5f5f;
}

.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.info {
    padding: 15px 30px;
    background: rgba(211, 211, 211, 0.4);
    margin-top: 12px;
    font-size: 25px;
    color: #5f5f5f;
}

.nav_section {
    padding: 23px 0 27px 0;
}

.nav_section h1 {
    margin-bottom: 30px;
    margin-top: 20px;
}

.margin-t-0 {
    margin-top: 0;
}

.margin-b-0 {
    margin-bottom: 0;
}

.margin-l-3x {
    margin-left: 30px;
}

.margin-l-2x {
    margin-left: 20px;
}

/* Team.css*/
.team_members_ul {
    padding-top: 25px;
}

.team_members_ul li {
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-bottom: 5px;
    padding-top: 5px;

    font-size: 20px;
    color: #5f5f5f;
}

.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.nav_section h1 {
    margin-bottom: 30px;
}

.address_datails {
    font-size: 20px;
    color: #5f5f5f;
}

.apart_datails {
    margin-top: 16px;
}



/*Contact Us*/
.border-b {
    border-bottom: 1px solid rgba(0, 109, 255, 0.2);
}

.nav_section h1 {
    margin-bottom: 30px;
}

.address_datails {
    font-size: 20px;
    color: #5f5f5f;
}

.apart_datails {
    margin-top: 16px;
}




/*Left Nav.css*/
.lef_nav_container {
    padding: 53px 0px 0 20px;
    min-width: 286px;
    height: auto;
}

.lef_nav_container ul, ul {
    padding: 0;
    list-style-type: none;
    margin-top: 0;
}

.lef_nav_container ul li a {
    font-size: 20px;
    color: #5f5f5f;
    margin-bottom: 8px;
    padding-left: 8px;
    display: inline-block;
    width: 100%;
    padding: 8px 0 8px 16px;
}


.lef_nav_container ul li a.active {
    background: rgba(211, 211, 211, 0.4);
    border: none;
}

.contat_us {
    padding-left: 5px;

}

.contat_us>a {
    color: #5F5F5F;
}

.contat_us>a:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {

    .nav_container {
        padding: 90px 10px 40px 10px;
    }
    .ant-collapse {
        width:100%;
    }
    .help_content_page {
        padding: 0px 0 30px 0px;
        border-left: 0;
        width: 100%;
    }
    /* .ant-collapse-content > .ant-collapse-content-box { */

        /* padding:0; */
    /* } */
    .info{
        margin-top:0;
    }

    .ant-collapse > .ant-collapse-item {
        background-color: #fff;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
        flex: inherit;
        font-size: 25px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        justify-content: center;
        flex-direction: row-reverse;
    line-height:25px;
}

.ant-collapse {
    border:0;
}
}
.border {
  border: 1px solid rgba(0, 109, 255, 0.2);
}

.scan-container.market-container {
  flex-direction: column;
}

.search-input-container {
  width: 508px;
  height: 44px;
}

.ant-input-prefix {
  border-right: 1px solid green;
  padding-right: 7px;
}

.scan-container.scan-container-trade {
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
}

.main-left {
  flex-grow: 1;
  max-width: calc(100% - 350px);
}

.trade_radio .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
  height: 41px;
  line-height: 41px;
  font-size: 20px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}

.trade_tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.trade_tabs.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
  margin: 0 20px;
}

.ant-tabs-tab {
  font-size: 18px;
  /* color: rgba(95, 95, 95, 0.5); */
  color: var(--conf-purchase);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--body_color);
}

.border-1x,
.card.main-right {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.card.main-right {
  min-width: 350px;
  max-width: 350px;
}

.main-left .ant-table-tbody > tr > td {
  border-bottom: 0;
}

.login_tabs {
  min-height: 400px;
  border: 1px solid #d7d7d7;
}

.login_tabs .ant-tabs-content-holder {
  display: flex;
  align-items: center;
  text-align: center;
}

.main-left .ant-table-thead > tr > th,
.main-left .ant-table-tbody > tr > td,
.main-left .ant-table tfoot > tr > th,
.main-left .ant-table tfoot > tr > td {
  padding: 0;
}

.main-left .ant-table-thead > tr > th {
  background-color: #fff;
  border-bottom: 0;
}

.main-left
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}

.trade_content .ant-table-thead > tr > th:nth-child(3),
.trade_content .ant-table-tbody > tr > td:last-child {
  text-align: right;
}

.trade_content .ant-table-thead > tr > th:nth-child(2),
.trade_content .ant-table-tbody > tr > td:nth-child(2) {
  text-align: center;
}

.orange .ant-tabs-ink-bar {
  background: var(--primary-color);
}

.orange .ant-tabs-tab:hover {
  color: var(--primary-color);
}

.border,
.ant-input-affix-wrapper {
  border: 1px solid rgba(0, 109, 255, 0.2);
  border-radius: 5px;
}

.scan-container.market-container {
  flex-direction: column;
  color: var(--body_color);
}

.search-input-container {
  width: 508px;
  height: 44px;
}

.ant-input-prefix {
  border-right: 1px solid rgba(0, 109, 255, 0.2);
  padding-right: 7px;
}

.tabs-container {
  width: 95%;
  margin: 67px auto 40px;
  min-height: 40px;
}

.market-container .ant-tabs-top > .ant-tabs-nav::before,
.market-container .ant-tabs-bottom > .ant-tabs-nav::before,
.market-container .ant-tabs-top > div > .ant-tabs-nav::before,
.market-container .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.market-container .ant-tabs-nav {
  padding-left: 60px;
}

.grey-strip {
  background: #d9d9d966;
  padding: 24px 21px 19px;
}

.white-strip {
  background: var(--market-table-color);
  color: var(--body_color);
  min-width: 100px;
  height: 32px;
  border-radius: 5px;
  width: auto;
}

.white-strip:hover {
  background: var(--market-table-color);
  /* color: var(--body_color); */
}

.margin-lr-2x {
  margin-left: 20px;
  margin-right: 20px;
}

.white-strip.last-item {
  width: auto;
  margin-left: auto;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}

.tab-body-container .ant-table-content {
  margin: 0 20px;
}

.market-container .ant-tabs-tab {
  color: rgba(95, 95, 95, 0.5);
}

.market-container .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 30px;
  color: var(--body_color);
}

.market-container .ant-tabs-tab:hover .ant-tabs-tab-btn {
  font-size: 30px;
  color: var(--body_color);
}

.market-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--body_color);
  font-size: 30px;
}

.market-container .ant-tabs-ink-bar {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.market-container .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 45px;
}

.ant-btn.btn-success {
  background-color: var(--primary-color);
  border-color: transparent;
  border-radius: 4px;
}

.ant-btn.btn-success:hover {
  background-color: var(--secondary-color);
  border-color: transparent;
  border-radius: 4px;
}

.ant-btn.btn-warn {
  border-color: transparent;
  border-radius: 4px;
}

.ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}

.ant-table-pagination.ant-pagination {
  display: none;
}

.market-container .ant-spin-dot {
  width: 50px;
  height: 50px;
}

@media (max-width: 767px) {
  .market_button_strips button {
    min-width: 50px;
    max-width: 90px;
    font-size: 12px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .market_button_strips .margin-lr-2x {
    margin-left: 8px;
    margin-right: 0;
  }

  .market-container .tab-body-container .ant-table-content {
    margin: 0 0;
  }

  .market-container .ant-tabs-tab + .ant-tabs-tab,
  .market-container .ant-tabs-tab:hover .ant-tabs-tab-btn,
  .market-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .market-container .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 15px;
  }

  .market-container .ant-tabs-nav {
    padding-left: 0;
  }

  .market-container h1 {
    font-size: 25px;
    padding: 0 16px;
  }

  .market-container .search-input-container {
    width: 100%;
    padding: 0 20px;
  }

  .market-container .ant-tabs-tab:nth-child(2) {
    margin-left: 20px;
  }
}



/* .ant-select, .ant-pagination-options {
  color: var(--body_color) !important;
  background-color: var(--body_background) !important;
} */

.ant-select-selector{
  /* color: var(--body_color) ; */
  background-color: var(--body_background) !important;
}
.ant-select{
border: 1px solid var(--border-color);
border-radius: 6px;
}
.ant-select-arrow, .ant-select-selection-item{
  color: var(--body_color) !important;

}
body {
    color: #5f5f5f;

}

.blog_container {
    padding-top: 90px;
}

.font_60x {
    font-size: 60px;
    line-height: 1.2;
}

@media (min-width: 992px) {
    .blog_Responsive .col-lg-6 {
        flex: 0 0 auto;
        width: calc(50% - 10px);
    }
}

/* .blog_Responsive .col-lg-6 {
    flex: 0 0 auto;
    width: calc(50% - 10px);
} */

.font_48x {
    font-size: 48px;
    line-height: 1.2;
}

.font_30x {
    font-size: 30px;
    line-height: 1.2;
}

.blog_flipicon_image {
    flex-basis: calc(62% - 20px);
    background: #E6E6E6;


}

/* .blog_flipicon_image img {
    position: absolute;
    transform: translatex(50%);
    bottom: 0px;
} */

/* .blog_flipicon_image_content {
    flex-basis: calc(38% - 20px);
    color: #5A5858;

} */

.flex-b-50 {
    flex-basis: calc(50% - 20px);
}

.border-1x {
    border: 1px solid rgba(0, 109, 255, 0.5);
}

.border-1x.orange {
    border: 1px solid #D7D7D7;
}

.flex-wrap {
    flex-wrap: wrap;
}

.margin-b-3x {
    margin-bottom: 30px;
}

.margin-tb-2x {
    margin-top: 20px;
    margin-bottom: 20px;
}

.padding-0 {
    padding: 0 0;
}

@media (max-width: 767px) {

    .blog_container .font_23x, .blog_container .default-link.font_20x, .blog_container .font_20x {
        font-size: 15px !important;


    }

    .blog_container .font_40x {

        font-size: 25px !important;
        text-align: center;
    }

    .blog_container {
        /* padding-top: 90px; */
        margin: 0 19px 19px;
    }

    .blog_container .border-1x.orange, .blog_container .border-1x {

        border: 0 !important;
    }

    .font_60x {
        font-size: 35px;

    }
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .loading-spinner {
    border: 1px solid;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(/static/media/loaderIcon.c8cece83.gif) center / 50px no-repeat;
  }
.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.scan-container.wd_container {
  align-items: start;
  flex-direction: column;
}

.bs_main label,
.placeholder_info {
  font-size: 13px;
  color: var(--body_color);
}

.bs_main label {
  padding-bottom: 5px;
}

.select_container {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 8px 16px;
}

.font_23x {
  font-size: 23px;
  color: #5f5f5f;
}

.disabled_button {
  width: -moz-fit-content;
  width: fit-content;
  background: rgba(95, 95, 95, 0.2);

  height: 42px;
  height: 55px;

  font-size: 20px;
  border-radius: 5px;
}

.w_fiat {
  width: 90%;
  margin: auto;
}

.w_fiat button {
  width: -moz-fit-content;
  width: fit-content;
}

.font_13x {
  font-size: 13px;
}

.danger_disabled {
  color: rgba(95, 95, 95, 1);

  border-color: rgba(95, 95, 95, 0.2);
}

.W_form .ant-row {
  display: block;
}

.W_form .ant-col-16 {
  max-width: 100%;
}

.W_form input {
  height: 55px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  font-size: 15px;
}

.W_form input:hover {
  border: 1px solid #d7d7d7;
}

.W_form {
  height: 350px;
  overflow-y: auto;
}

.W_form label,
.placeholder_info {
  color: #5f5f5f;
}

.border-t-1x {
  border-top: 1px solid #d7d7d7;
}

.border-1x {
  border: 1px solid #d7d7d7;
}

.max_400 {
  max-width: 400px;
}

.padding-1x {
  padding: 10px;
}

.brand_opacity_5 {
  color: rgba(95, 95, 95, 0.5);
}

.padding-tb-1x {
  padding-top: 10px;
  padding-bottom: 10px;
}

.flex_buttons button {
  width: 47%;
}

.ant-radio-inner::after {
  background-color: var(--primary-color);
}

.ant-radio-checked::after,
.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color);
}

.ant-radio-wrapper,
.ant-radio-wrapper span {
  font-size: 15px;
}

.wd_container .helper_text {
  color: rgba(95, 95, 95, 0.5);
  font-weight: normal;
  font-family: AvenirRegular;
}

.wd_container .radio_text {
  font-family: AvenirHeavy;
  color: #5f5f5f;
  font-size: 16px;
}

.w_50 {
  width: calc(50% - 20px);
}

.font_weight_800 {
  font-weight: 800;
}

.font_40x {
  font-size: 40px;
}

.deposit-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 54px !important;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 21%;
}

.bs_main label.ant-radio-wrapper {
  color: #5f5f5f;
}

.bg_icon {
  background: var(--primary-color);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  padding: 2px;
}

.W_form .ant-col-8 {
  max-width: 100%;
}

.brand_color {
  color: #5f5f5f;
}

.popover_container {
  width: 300px;
  min-height: 304px;
}

.disc_ul {
  list-style-type: disc;
  margin-left: 20px;
}

.ant-popover-inner-content {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.margin-l-1x {
  margin-left: 10px;
}

.ant-popover-inner {
  border-radius: 5px;
}

.popover_container_link,
.popover_container_link:hover {
  color: #5f5f5f;
  text-decoration: underline;
}

.amount_container input {
  border: none;
}

.ant-btn.disabled_button:hover,
.ant-btndisabled_button:focus {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.text_line {
  text-decoration: underline;
}

.ant-select-selection-placeholder {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
}

.border-r-1x {
  border-right: 1px solid #5e5e5e;
}

.padding-r-1x {
  padding-right: 10px;
}

.padding-l-1x {
  padding-left: 10px;
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.custom_table .ant-table-content::-webkit-scrollbar {
  height: 7px !important;
}
.custom_table .ant-table-content::-webkit-scrollbar-thumb {
  background-color: #5f6673 !important;
  border-radius: 4px;
}
.custom_table .ant-table-content::-webkit-scrollbar-track {
  background-color: #35393f !important;
}
.custom_table .ant-table-content::-webkit-scrollbar-thumb:hover {
  background-color: #484f59 !important;
}
.reset-button {
  background: none !important;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 50px 0 0;
  min-width: 125px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 50px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 20px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-start;
}

.filter-item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(16% - 10px); /* 25% width for 4 items per row */
  min-width: calc(16% - 10px);
}

@media (max-width: 1024px) {
  .filter-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 10px); /* 50% width for 2 items per row on tablets */
    min-width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .filter-item {
    flex: 1 1 100%; /* 100% width for 1 item per row on mobile */
    min-width: 100%;
  }
}
.large_card .reset_button {
  top: 111px;
  right: -18px;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .large_card {
    max-width: 90%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}

.bank_info_card {
    min-width: 550px;
}

.font_28x {
    font-size: 28px;
}

.no-outline, .outline-none {
    outline: none;
}

@media (max-width: 767px) {

    .deposit_ontainer {
        display: block !important;
    }

    .deposit_ontainer .order_history button {
        margin-left: 0;
    }

    .top_heading {
        font-size: 35px;
    }

    .amount_container {
        font-size: 22px;
    }

    .d_crypto_Container {
        display: block !important;
    }

    .d_crypto_Container .crypto_con_button {
        /* text-align: center; */
        text-align: end;
    }

    .d_crypto_status {
        justify-content: space-between;
    }

    .responsive_recent_deposits .d-flex,
    .responsive_recent_deposits.d-flex {
        display: block !important;
    }

    .responsive_recent_deposits .font_15x {
        font-size: 10px;
    }

    .wallet_funding {
        padding-left: 20px;
    }

    .recent_deposit_container {
        padding: 0;
        border: 0;
    }

    .w_fiat .font_40x {
        font-size: 15px;
        font-weight: bold;
    }

}
.about-container {
    height: 600px;
}

.handsImage {
    width: 50%;
    margin: auto;
    transform: translateY(-60%);
}

.row{
    width: 100%;
}
.disignation {
    font-size:12px;
}
.employee_name {
    font-size:15px;
}

.main_container {
    padding: 0 10%;
}

.main_container .col-lg-4 {
    margin-top:20px;
}

@media (max-width: 767px) {

    .about-container {
        height: 338px;
    }
    .font_40x {
        font-size: 25px;
    }
    .font_30x {
        font-size: 15px;
        line-height: 1.2;
    }
    

.handsImage {
    width: 63%;
}


}
/* .accounts_container .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  background: var(--dash-bg);
  color: var(--body_color);
  padding-left: 55px;
} */

.basic_info > div,
.security_container.border-1x,
.preference_info {
  /* border: 1px solid var(--border-color) !important; */
  border-radius: 5px;
  /* background: var(--body_background); */
}

.basic_info .border-b-1x,
.security_container .border-b-1x,
.authenticator_container.border-1x,
.security_change.card,
.security_change .ant-input-password.ant-input-affix-wrapper {
  border-color: var(--border-color) !important;
}

.ant-tabs {
  color: var(--body_color);
}

.border-1x-orange {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}
.ant-switch-checked {
  background: var(--primary-color);
}

.basic_funtion > div {
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 24px;
  background-size: auto;
  font-size: 15px;
}

.apple_pay {
  background-image: url(/static/media/applePayA.cb822446.svg);
}
.staking {
  background-image: url(/static/media/stakingA.044b5d73.svg);
}
.wire_transfer {
  background-image: url(/static/media/wireA.a9185a24.svg);
}

.otc_trading {
  background-image: url(/static/media/otcA.16d6caf2.svg);
}
.api_trading {
  background-image: url(/static/media/apiA.b7ad4c94.svg);
}
.adv_trade {
  background-image: url(/static/media/tradeA.09ac83b8.svg);
}
.usd_deposit {
  background-image: url(/static/media/dollarA.90b5c699.svg);
}
.crypto_deposit {
  background-image: url(/static/media/airplane.411f031c.svg);
}

.buy_Sell_convert {
  background-image: url(/static/media/BSArrowA.c21099d0.svg);
}

.bank_trns_debit {
  background-image: url(/static/media/RectangleA.48604cd7.svg);
}

.security_container .btn-primary {
  background: var(--primary-color);
  height: 38px;
  border-color: var(--primary-color);
}

.security_container .height_28x {
  height: 28px;
  font-size: 13px;
  border-radius: 5px;
}
.padding-l-24px {
  padding-left: 24px;
}

.border_radius_5x {
  border-radius: 5px;
}

.basic_funtion .disabled_button,
.basic_funtion .disabled_button:hover {
  background: #e4e4e4;
  color: #5f5f5f;
  border-color: #e4e4e4;
  font-size: 18px;
}

.security_form_element .ant-row {
  display: block;
}

.bs_main .security_form_element label,
.security_form_element .placeholder_info {
  font-size: 13px;
  color: var(--body_color);
}

.ant-tabs-tab:hover {
  color: var(--body_color);
}

.ant-tabs-ink-bar {
  background: var(--primary-color);
  height: 0.3px !important;
}
.font_17x {
  font-size: 17px;
  color: var(--body_color);
}

.dis-btn {
  background-color: var(--body_background);
  color: var(--body_color);
}

.dis-btn:hover {
  background-color: var(--body_background);
}
.basic-det {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .basic-det {
    /* flex-direction: column; */
  }
  .d-none-mob {
    display: none !important;
  }
}

div.custom-sel::-webkit-scrollbar {
  width: 5px !important;
}

div.custom-sel::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 6px;
}

@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75%,
  100% {
    content: '...';
  }
}

.dots-animation::after {
  display: inline-block;
  content: '';
  animation: dots 1.5s infinite;
}

.inputmaskfield:focus-visible {
  outline: none;
}

.main-box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--main-body);
    background-color: rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-box{
    position: relative;
    top: 75px;
    width: 950px;
    background-color: var(--main-body);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 0.2);
}

.congrats {
    justify-content: center;
    font-size: 50px;
    color: #FFB300;
    font-weight: bold;
}

.logo-img {
    width: 150px;
    height: 150px;
}

.large-text {
    color: var(--body_color);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    font-size: 14px;
    font-weight: bold;
    font-family: "Inter";
}
.text-box {
    color: var(--body_color);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
    font-size: 13px;
    font-family: "Inter";
}

.text-box-2 {
    color: var(--body_color);
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
    /* padding-top: 1.5rem; */
    font-size: 20px;
    font-family: "Inter";
}

.button-box {
    color: #fff;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    /* margin-bottom: 1.5rem; */
    display: flex;
    justify-content: center;
    font-family: "Inter";
    font-size: 13px;
}

.button-btn {
    background-color: #FFB300;
    padding-left: 5.2rem;
    padding-right: 5.2rem;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    /* border-radius: 0.125rem;  */
    border: 0;
    color: #000;
}

.button-btn:hover {
    background-color: #FFD000;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
    .second-box{
        top: 75px;
        width: 100vw;
    }
    
    .logo-img {
        width: 80px;
        height: 80px;
    }

    .congrats {
        font-size: 30px;
    }
} 
.notification_container .border-b,
.help_content_page.notification_container{
    border-color:#E4E4E4;
}

@media (max-width: 767px) {
.nav_container.notification_nav_container {
    padding: 2px 10px 40px 10px;
} 

.nav_main_header>div:last-child{
    border:none;
}
}

.width_50 {
  width: 50%;
}

.apply_button,
.apply_button:hover,
.apply_button:active,
.apply_button:focus {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 20px;
  height: 46px;
}

.margin-t_300x {
  margin-top: 300px;
}

.career_icon {
  background-image: url(/static/media/CareerIcon.15259b2c.svg);
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 50px 0 0;
  min-width: 125px;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 20px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.large_card .reset_button {
  top: 111px;
  right: -18px;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .large_card {
    max-width: 90%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }

  .transaction_filters > div > .ant-select {
    width: calc(100%);
  }
}

.hive-btn,
.hive-btn:disabled {
  background-color: #ffb300;
  border-color: #ffb300 !important;
  font-size: 20px;
  height: 55px;
  color: #fff;
  border-radius: 0;
}

.hive-btn:hover,
.hive-btn:focus,
.hive-btn:active {
  background-color: #ffd000 !important;
  border-color: #ffd000 !important;
  color: white !important;
}

.hive-btn-2 {
  background-color: #11be6a;
  border-color: #11be6a !important;
  font-size: 20px;
  height: 55px;
  color: #fff;
  border-radius: 0;
}

.hive-btn-2:hover {
  background-color: #0ea55a !important;
  border-color: #0ea55a !important;
}

.text-underline-forgot {
  text-decoration: underline;
}

.text-underline-forgot:hover {
  color: #ffb300;
  text-decoration: underline;
}

.email-icon {
  position: absolute;
  right: 21px;
  transform: translate(50%, 50%);
}

.input_height {
  background: blue;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
}
.drop-hexagon {
  position: relative;
  width: 27px;
  height: 31px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  overflow: hidden;
}

.drop-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .log-img {
    display: none !important;
  }
}

.sub-page .card-img-top {
  width: 45px;
  height: 40.86px;
  transition: trasnform 330ms ease-in-out !important;
}

.sub-page .card-img-top:hover {
  transform: scale(1.3);
  transition: trasnform 330ms ease-in-out !important;
}

.card-container {
  min-width: 137px;
  max-width: 137px;
}
.sub-page .card {
  min-width: auto !important;
  min-height: auto !important;
  align-items: center !important;
  padding: 10px 10px 10px !important;
  width: 120px !important;
  height: 80px !important;
  border: none !important;
  border-radius: 0 !important;
}

.sub-page .card-body {
  padding: 0;
}

.sub-page h5 {
  width: auto !important;
  background-color: transparent;
  border: none;
  font-size: 10px !important;
  margin-top: 8px;
  color: #000 !important;
  /* color: var(--body_color); */
}

.sub-page .card-text {
  justify-content: center;
  text-align: center;
  color: #000 !important;
  /* color: var(--body_color); */
}

.sub-logos .col {
  width: 8.5%;
}

.sub-page a {
  text-decoration: none !important;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .sub-page .card-img-top {
    width: 30px !important;
    height: 24px !important;
    transition: trasnform 330ms ease-in-out !important;
  }
  .card-container {
    min-width: 90px;
    max-width: 120px;
  }

  .sub-page .card {
    /* padding: 0 !important; */
    /* padding-top: 5px !important; */
    /* width: 80px !important; */
    /* height: 66px !important; */
    width: 90px !important;
    padding-inline: 5px !important;
    line-height: 0.5;
  }

  .sub-page .col {
    width: 25.1% !important;
    margin-top: 0;
  }

  .sub-page h5 {
    margin-top: 6px;
    font-size: 9px !important;
  }
}

@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75%,
  100% {
    content: '...';
  }
}

.dots-animation::after {
  display: inline-block;
  content: '';
  animation: dots 1.5s infinite;
}

.hive-container {
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--body_color) !important;
  margin-top: 100px;
}

.side-container {
  padding: 30px 20px;
  border-radius: 15px;
}

.greet-container {
  width: 1100px;
  max-width: 1100px;
  /* height: 650px; */
  padding: 20px;
  margin-left: 30px;
}

.honeycomb-container {
  width: 700px;
  /* height: 650px; */
  padding: 20px;
}

.honeybee-container {
  background-color: var(--hive-honeybee);
  width: 1200px;
  /* height: 700px; */
  padding: 20px;
  margin-left: 10px;
}

.lh_32x {
  line-height: 32px;
  overflow-wrap: break-word;
}
.hexagon {
  position: relative;
  width: 140px;
  height: 144px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  margin: 0 auto;
  overflow: hidden;
}

.hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.profile-hexagon {
  position: relative;
  width: 168px;
  height: 175px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  margin: 0 auto;
  overflow: hidden;
}

.profile-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.bee-hexagon {
  position: relative;
  width: 57px;
  height: 59px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* margin: 0 auto; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bee-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: cover; Maintain aspect ratio and cover the entire hexagon */
}

.side-hexagon {
  position: relative;
  width: 77px;
  height: 79px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* margin: 0 auto; */
  overflow: hidden;
}

.side-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}
.permission {
  background-color: #ffb300;
  border-radius: 2px;
  padding: 6px 68px;
  font-size: 18px;
}

.MuiRating-iconEmpty {
  color: var(--body_color) !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--body_color) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--conf-purchase) !important;
}

.post-input .MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--body_color) !important;
  border-radius: 2px !important;
  border: 1px solid var(--border-color) !important;
  background-color: var(--body_background) !important;
}

.post-input .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.ant-picker-header button {
  width: auto;
}

.ant-picker-header button:hover,
.ant-picker-header button:focus,
.ant-picker-header button:active {
  background: none;
}

/* .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-color: #ffb300;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before
{
  background: #ffb30060;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #ffb300;
} */
.ant-picker-range .ant-picker-active-bar {
  background: #ffb300;
  height: 0px;
}

.ant-picker-input > input {
  color: var(--body_color) !important;
}

.ant-picker-panel-container {
  /* color: var(--body_color) !important; */
  background-color: var(--body_background);
}

.ant-picker-focused .ant-picker-separator,
.ant-picker-header,
.ant-picker-content th,
.ant-picker-cell,
.ant-picker-header button {
  color: var(--body_color) !important;
}

.ant-picker-cell-disabled {
  opacity: 0.25;
}
.ant-picker-focused,
.ant-picker {
  border-color: #ffb300 !important;
  box-shadow: none;
  background-color: var(--body_background);
}

.ant-picker-separator,
.ant-picker-suffix {
  color: var(--body_color);
  vertical-align: super;
}

.createDateRangePicker {
  background: var(--body_background) !important;
  color: var(--body_color) !important;
}

/* .ant-picker-panel-container{
  background: var(--body_background);
} */

.ant-picker-focused:hover {
  border-color: #ffb300;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background-color: #ffd000 !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #ffd000 !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #ffb300 !important;
}
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-color: #ffb300 !important;
  fill: #ffb300 !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.createDateRangePicker
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #ffb30060 !important;
}

.createDateRangePicker .ant-picker-cell-inner::after {
  background: #ffd000 !important;
}

.createDateRangePicker .ant-btn-primary {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
}

.com-btn {
  background-color: var(--body_background);
  color: var(--dark_text);
  border: none;
  height: 40px !important;
  width: 50% !important;
}

.com-btn:hover,
.com-btn:focus,
.com-btn:active {
  font-size: larger;
  background-color: var(--body_background);
  color: var(--dark_text);
  border: none;
}

.greet-slide-div {
  max-width: 100% !important;
  max-height: 500px !important;
}

.greet-slide-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.slick-slide {
  width: 432px !important;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .honeycomb-container {
    width: 100%;
    /* height: 650px; */
    padding: 0px;
    padding-top: 40px;
  }

  .hive-container {
    width: 100%;

    background-color: transparent;
    color: var(--body_color) !important;
  }

  .side-container {
    width: 100%;
    /* height: 650px; */
    padding: 10px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .greet-container {
    width: 100%;
    max-width: 350px;
    /* height: 650px; */
    padding: 10px;
    margin-left: 0px;
  }

  .greet-slide-div {
    max-width: auto !important;
    max-height: auto !important;
  }

  .greet-slide-img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .slick-slide {
    width: 285px !important;
  }

  .custom-table {
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
  }

  .honeybee-container {
    width: 100%;
    /* max-width: 100%; */
    padding: 10px;
  }

  .com-btn {
    font-size: 10px;
    padding-inline: 0;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: transparent;
    width: 80%;
    margin-inline: auto;
  }
}

.atn-btn-hover {
  background-color: #ffc233; /* Normal state color */
  border-color: #ffc233;
}

.atn-btn-hover:hover {
  background-color: #ffd55f; /* Lighter color for hover state */
  border-color: #ffd55f;
}

.link-btn {
  color: #ffb300 !important;
  background: transparent;
  width: -moz-fit-content;
  width: fit-content;
}

.link-btn:hover {
  color: #ffd000 !important;
  background: transparent;
}

.font_70x {
  font-size: 70px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_20x {
  font-size: 20px;
}

.my-tabs .ant-tabs-tab {
    padding: 0;
    color: #878787 !important;
}


.my-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus {
    padding: 0;
    color: #000;
}

.my-tabs .ant-tabs-ink-bar {
    /* background:  #000; */
    display: none;
  }

  .my-tabs .ant-tabs-top > .ant-tabs-nav::before{
    display: none;
  }
.bs_container.medium_card {
  min-width: 700px;
}

.custom-select .ant-select-dropdown-menu-item {
  color: white; /* Set your desired text color */
}

.custom-select .ant-select-dropdown-menu-item:hover {
  background-color: var(--dropdown-hover); /* Set your desired hover background color */
  color: white; /* Set your desired hover text color */
}

.common__token {
  color: var(--body_color);
  background-color: var(--body_background);
  border-color: var(--border-color);
}

.common__token:hover {
  color: var(--body_color);
  background-color: var(--disabled-btn);
}

.medium_card .light_button {
  padding: 6px 15px 10px 40px;
  background-repeat: no-repeat;
  background-position: left 10px center;
}

.bs_wallet_top {
  background: var(--primary-color);

  max-width: calc(100% - 160px);
  margin: 0 auto 20px;
  padding: 20px;
  border-radius: 5px;
}

.margin-l-0_5x {
  margin-left: 5px;
}

.margin-r-0_5x {
  margin-right: 5px;
}

.color_white {
  color: #fff;
}

.not_so_large_card {
  margin: 0 auto;
  max-width: calc(120% - 160px);
  /* margin-bottom: 300px; */
  padding-top: 0;
}

.full_width_tabs {
  width: calc(100% - 160px);
}

/* .custom_table tr > td.ant-table-cell:nth-child(3) {
  text-align: right;
} */

.orange_input.ant-input-affix-wrapper,
.orange_input.ant-input-affix-wrapper:hover,
.orange_input input,
.orange_input input:hover {
  border-color: rgba(246, 96, 54, 0.3);
}

.orange_input.ant-input-affix-wrapper {
  height: 30px;
}

.bs_wallet .ant-input-prefix {
  border-color: rgba(246, 96, 54, 0.3);
}

.bs_wallet .ant-tabs-extra-content {
  margin-right: 20px;
  margin-bottom: 10px;
}

.bs_wallet .ant-tabs-nav-wrap {
  padding-left: 20px;
}

.bs_wallet_top_banner {
  /* background-position-y: bottom 0; */
  /* padding-bottom: 320px; */
  /* background-image: url('../../assets/coming_soon.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover; */
  width: calc(100% - 50px);
}

.bs_wallet_buttons {
  position: absolute;
  right: 0;
  top: 10px;
}

.small-btn{
  width: auto;
  height: 25px;
  padding: 1px 7px 0px 40px !important;
  /* padding: 4px 15px 6px 40px !important; */
}

.custom_table 
.ant-table-tbody 
.ant-table-cell
{
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-thead > tr > th {
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-thead > tr > th:hover {
  background-color: var(--disabled-btn);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-cell-row-hover{
  background-color: var(--disabled-btn) !important;
  color: var(--body_color);
  border-color: var(--border-color);
}

@media (max-width: 767px) {
  .bs_wallet_top {
    max-width: 90%;
    display: block !important;
  }
  .bs_wallet_top div:nth-child(2) a {
    margin-left: 0;
  }
  .bs_wallet_buttons {
    position: inherit;
  }
  .bs_wallet_table table th,
  .bs_wallet_table table td {
    padding: 16px 8px;
  }
  h1 {
    font-size: 25px;
  }
}


.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 5px 0 0;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 15px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.not_so_large_card .reset_button {
  top: 110px;
  right: -20px;
  margin-top: 23px;
  height: 33.5px;
  font-size: 15px;
  padding: 2px 12px;
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
  margin-left: 0px;
  margin-right: 0px;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    font-size: 15px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 15%;
  font-size: 15px;
}

.ant-select-arrow {
  height: 13px;
  font-size: 15px;
}

.ant-select-selection-placeholder {
  font-size: 15px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .not_so_large_card {
    max-width: 100%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}


.cover-page .card-img-top{
    width: 45px;
    height: 40.86px;
    transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card-img-top:hover{
    transform: scale(1.3);
    transition: trasnform 330ms ease-in-out !important;
}

.cover-page .card{
    min-width: auto !important;
    min-height: auto;
    align-items: center;
    padding: 10px 10px 10px;
    width: 100px;
    height: 80px;
    border: none;
    border-radius: 0;
    background-color: #FFB300;
}

.cover-page .card-body{
    padding: 0;
}


.cover-page  h5{
    width: auto !important;
    background-color: transparent;
    border: none;
    font-size: 10px !important;
    margin-top: 8px;
    color: #000 !important;
    /* color: var(--body_color); */
}

.cover-page .card-text{
    justify-content: center;
    text-align: center;
    color: #000 !important;
    /* color: var(--body_color); */
}

.up-logos .col{
    width: 7%;
}

.cover-page a{
    text-decoration: none !important;
}
.my-tabs .ant-tabs-tab {
    padding: 0;
}

.my-tabs .ant-tabs-ink-bar {
    background: #000;
  }

  .MuiSelect-icon {
    color: var(--body_color) !important;
  }

  .MuiMenu-list{
    background: var(--body_background);
    color: var(--body_color);
    border: none;
  }

  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper
  {
    background: var(--body_background);

  }

  .MuiChartsAxis-tickLabel, .MuiChartsLegend-label {
    fill: var(--body_color) !important;
  }


  .MuiChartsAxis-line, .MuiChartsAxis-tick {
    stroke: var(--body_color) !important;

  }

  @media screen and (min-width: 350px) and (max-width: 1000px) {
  
    .my-tabs .ant-tabs-tab {
      font-size: 14px;
    }
  
  }
.bs_container.medium_card {
  min-width: 700px;
}

.custom-select .ant-select-dropdown-menu-item {
  color: white; /* Set your desired text color */
}

.custom-select .ant-select-dropdown-menu-item:hover {
  background-color: var(
    --dropdown-hover
  ); /* Set your desired hover background color */
  color: white; /* Set your desired hover text color */
}

.common__token {
  color: var(--body_color);
  background-color: var(--body_background);
  border-color: var(--border-color);
}

.common__token:hover {
  color: var(--body_color);
  background-color: var(--disabled-btn);
}

.medium_card .light_button {
  padding: 6px 15px 10px 40px;
  background-repeat: no-repeat;
  background-position: left 10px center;
}

.bs_wallet_top {
  background: var(--primary-color);

  max-width: calc(100% - 160px);
  margin: 0 auto 20px;
  padding: 20px;
  border-radius: 5px;
}

.margin-l-0_5x {
  margin-left: 5px;
}

.margin-r-0_5x {
  margin-right: 5px;
}

.color_white {
  color: #fff;
}

.not_so_large_card {
  margin: 0 auto;
  max-width: calc(120% - 160px);
  /* margin-bottom: 300px; */
  padding-top: 0;
}

.full_width_tabs {
  width: calc(100% - 160px);
}

/* .custom_table tr > td.ant-table-cell:nth-child(3) {
  text-align: right;
} */

.orange_input.ant-input-affix-wrapper,
.orange_input.ant-input-affix-wrapper:hover,
.orange_input input,
.orange_input input:hover {
  border-color: rgba(246, 96, 54, 0.3);
}

.orange_input.ant-input-affix-wrapper {
  height: 30px;
}

.bs_wallet .ant-input-prefix {
  border-color: rgba(246, 96, 54, 0.3);
}

.bs_wallet .ant-tabs-extra-content {
  margin-right: 20px;
  margin-bottom: 10px;
}

.bs_wallet .ant-tabs-nav-wrap {
  padding-left: 20px;
}

.bs_wallet_top_banner {
  /* background-position-y: bottom 0; */
  /* padding-bottom: 320px; */
  /* background-image: url('../../assets/coming_soon.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover; */
  width: calc(100% - 50px);
}

.bs_wallet_buttons {
  position: absolute;
  right: 0;
  top: 10px;
}

.small-btn {
  width: auto;
  height: 25px;
  padding: 1px 7px 0px 40px !important;
  /* padding: 4px 15px 6px 40px !important; */
}

.small-btn:hover {
  width: auto;
  height: 25px;
  padding: 0px 20px 0px 35px !important;
}

.ant-checkbox-inner,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-input {
  background-color: var(--body_background);
  border-color: var(--border-color);
  color: var(--body_color);
}

.ant-input-password-icon.anticon {
  color: var(--body_color);
}
.custom_table .ant-table-tbody .ant-table-cell {
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table .ant-table-thead > tr > th {
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table .ant-table-thead > tr > th:hover {
  background-color: var(--disabled-btn);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table .ant-table-cell-row-hover {
  background-color: var(--disabled-btn) !important;
  color: var(--body_color);
  border-color: var(--border-color);
}

@media (max-width: 767px) {
  .bs_wallet_top {
    max-width: 90%;
    display: block !important;
  }
  .bs_wallet_top div:nth-child(2) a {
    margin-left: 0;
  }
  .bs_wallet_buttons {
    position: inherit;
  }
  .bs_wallet_table table th,
  .bs_wallet_table table td {
    padding: 16px 8px;
  }
  h1 {
    font-size: 25px;
  }
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 5px 0 0;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 15px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.large_card .reset_button {
  top: 111px;
  right: -18px;
  margin-top: 23px;
  height: 33.5px;
  font-size: 15px;
  padding: 2px 12px;
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
  margin-left: 0px;
  margin-right: 0px;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    font-size: 15px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 15%;
  font-size: 15px;
}

.ant-select-arrow {
  height: 13px;
  font-size: 15px;
}

.ant-select-selection-placeholder {
  font-size: 15px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .large_card {
    max-width: 90%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}

/* .custom_table tr > td.ant-table-cell:nth-child(3) {
  text-align: right;
} */

.custom_table 
.ant-table-tbody 
.ant-table-cell
/* ,.custom_table .ant-table-placeholder .ant-table-cell */
{
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-thead > tr > th {
  background-color: var(--body_background);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-thead > tr > th:hover {
  background-color: var(--disabled-btn);
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table 
.ant-table-cell-row-hover{
  background-color: var(--disabled-btn) !important;
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table .ant-table-placeholder .ant-table-cell:hover
{
  background-color: var(--disabled-btn) !important;
  color: var(--body_color);
  border-color: var(--border-color);
}

.custom_table
.ant-empty-description {
  color: var(--body_color);

}

.bs_main label,
.placeholder_info {
  font-size: 13px;
  color: var(--body_color);
}

.capt-tabs .ant-tabs-tab {
  padding: 0;
  color: white;
}

.capt-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus {
  padding: 0;
  /* color: #c5bdbd; */
}

.capt-tabs .ant-tabs-ink-bar {
  display: none;
}

.capt-tabs .ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}

.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  background-color: var(--disabled-btn);
  color: var(--body_color);
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .capt-tabs .ant-tabs-tab {
    font-size: 14px;
  }
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 5px 0 0;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 15px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.not_so_large_card .reset_button {
  top: 110px;
  right: -20px;
  margin-top: 23px;
  height: 33.5px;
  font-size: 15px;
  padding: 2px 12px;
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
  margin-left: 0px;
  margin-right: 0px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 55px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  font-size: 15px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 15%;
  font-size: 15px;
}

.ant-select-arrow {
  height: 13px;
  font-size: 15px;
}

.ant-select-selection-placeholder {
  font-size: 15px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .not_so_large_card {
    max-width: 100%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}

.filter-item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(16% - 10px); /* 25% width for 4 items per row */
  min-width: calc(16% - 10px);
}

@media (max-width: 1024px) {
  .filter-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 10px); /* 50% width for 2 items per row on tablets */
    min-width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .filter-item {
    flex: 1 1 100%; /* 100% width for 1 item per row on mobile */
    min-width: 100%;
  }
}

.resource-page .card-img-top{
    width: 45px;
    height: 40.86px;
    transition: trasnform 330ms ease-in-out !important;
}

.resource-page .card-img-top:hover{
    transform: scale(1.3);
    transition: trasnform 330ms ease-in-out !important;
}

.resc .resource-page .card{
    min-width: auto !important;
    min-height: auto;
    align-items: center;
    padding: 10px 10px 10px;
    width: 100px;
    height: 80px;
    border: none;
    border-radius: 0;
    background-color: var(--main-body);
}

.resource-page .card-body{
    padding: 0;
}


.resc .resource-page  h5{
    width: auto !important;
    background-color: transparent;
    border: none;
    font-size: 10px !important;
    margin-top: 8px;
    color: var(--body_color) !important;
    /* color: var(--body_color); */
}

.resource-page .card-text{
    justify-content: center;
    text-align: center;
    color: var(--body_color) !important;
    /* color: var(--body_color); */
}

.up-logos .col{
    width: 7%;
}

.resource-page a{
    text-decoration: none !important;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {

    
    .resource-page .card-img-top{
        width: 30px !important;
        height: 24px !important;
        transition: trasnform 330ms ease-in-out !important;
    }

    .resc .resource-page .card{
        /* padding: 0 !important; */
        /* padding-top: 5px !important; */
        /* width: 80px !important; */
        /* height: 66px !important; */
        width: 90px !important;
        padding-inline: 5px !important;
        line-height: 0.5;
    }  
      } 
.folder{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAABMCAYAAAAGEBuHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASySURBVHgB7Zs5jxRHFMf/1bO7eNfLGgOWhdYBkgNbMrJkh7YsObNsyYccOLAsy06c2A4ICCAh4QMQEhGASDgkrg9ABAkRJAREgLiRENfO7Ex38a+amaWP0sybmZ5dtvv9Rm/q6Krqqjev65ouQFEURVEURVEURVEURak1BiVgz+MzOh+ytHdhsdW7CZa86wRY6MW/Q3eR4Xnvh5cF70/oRtjC6y7uCh7je/M3mqgAEyvZnsV3VM4JlrQdJf1o3YKx1/yEw6gAESbAXsUsGthP1e5AmQp2RPjPnsFHqAATKRn38Se/v8E0sPgYc9iHCjC29dlj7Gvfxw16i9Y2T2lgNCxlhZJkYmOGvzQ/4xo2MeMr+TyOM/cfhQtOubvGLPk55WkuzuAyn7dfzA94hE3KDMbAnsOvbPzvwYvbMP5Pt5XS7MnazfAVOjhK34/YIGhQO2k8u1mXJp+sDv0reIk75jc+aQJGVoc9yVnEvH98lwsXFyg7QpkCfucmqXDSkxblBfK1bPPaEVp0ozcFNHQNP25MiXzY+e2aO9lY069fxLtaX6s9lE9TV2PG7+Hs54akKJEl2wv4l5XfxaJdM7/mDZaDCd3v+rBXwb4g54YUPrQCnMUY/J9Jbwppsu6kmIFlNVijOQiRdRcW//D7c28fgxrRQn2I8Z40qeyxKncGXA0SfCJNKrXkjVe0ybmD/GXj2l8c4r6AEJmSTW722o3LNrzvj1LhUHzf388XpcJRKs7k0m0kTsH3kO8qH0CI1JIPsaGnM3Fup8Jt5ayXNb1tjDB/kVry7UDcpIvyzUPIeBL57EKmpg6UPEZuYnWxxWkg3p1RJUspLn5UyeuAdhelEhr4IvlcSqbkRmk7AtXByncwpUpWi88zwppAprzYLzuUMZFuEI21ua90kSk5NF3RnTkxUiXPFuJUyWKkA9+o/z0rKaSWXNwMUUsWI1NyJN9xUorIlJz4lwazqCWL0YFvHZAOfDpPLlLyBlGCLYW4ulny1Lc6Ta670K7CaW5enlRCrLOLAqX/x2dqPvClX214E1eykrvvW1ad0fbMrXxnUqrk7Oyijn1yceBbhBBpd1GHTXsz0lWDDyBEuhiZGXrTqlO05JLf6oTuwgUseclamalJuwtVclFTi7gUWKSJsobJKrmOA19IUy9lCxL9j09KSFNtWb8s3btQJYeYky1IpK/OzmKa2AHh/AGfmQH50weC3Gvrccqf5K7nJVROOi50mCzBX/w+gCFILTQ8hYtTkm5IkqvssMYBw09G9cOhZ88OCU8LEzxQV0BqyUuZijul3sH6NSZNgreJnZJE4y2rHfp2nNPBdslcWWbJznrqM23rn3WK/WlUg44/mGTXOsTYhxOfrolTfrxaHVRgubMGV6GEExvjD0C4G3d64ir9Ct1T06v+GK8LWx9uebH+sO8Kr7m4/vHeFsOrPo9lnsina3sxPbG+rBavNXmnV8zZ9Hk6Po/L31XYDNO1vdJiPuQdPKE/oj+mf5nf1xneTf+3iI0p9zmVKTnCzd5aveUVY3CP7l24Y1aWvbPFLcY9ZDWfMe0Kq9rhRmAb2yhz9F9kIw7Cll15RVEURVEURVEURVEqz2vlbDi7YbE20wAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    min-width: 89px;
    min-height: 75px;
}

.folder:hover{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAABMCAMAAAAxzuu1AAAA3lBMVEUAAAD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD/swD3v1kGAAAASnRSTlMA/6+T/TZ+laMB18it94SgxS6JCKaYEuQEqCDRhj61Y3NOq86+JVlq70UUblPs+/IKwWWP6R3nDuA1jHkoQ7cXsitHXtwxnBq6SwDJym0AAAGcSURBVHic7dfZboJAGIZhEQVEEERUxB33fbdad7vf/w1Vj2riR4zyT9OkvKfAEzIMwxAI+Pn5+f3dDptyfVwSDF7LFKp6RV5Mm2o1QQAfeyHuuo532BoBl+NeZ57lIrrjU7xXeJjGMBeKe5QlF5jj9LYnuOsyFuf2N65tLw9r23I5mHIbi3N9XtQkyTQzjqMEr1Ic+Txma8Q2Yjk8L+4JPo2sZ/ZUnpk8ZiaLSK5QyAKSu8zkMIUcYybnmMkGM/mZmTz5XXlJIcMPBMk9QznPTN4wk4/M5C9mcpmZTLLWQbnOTB4zkxvM5A6FDL+DJQo5ieQVhawhWaCQTSTHKGQFyfyj2uX+tYBkkUIeITn5qHyZjOR3CjmNZI1CHkSA7P6Lck/ol9QkkVNAzpDINpAVErkG5D2JjDajWxIZLXY6iYwWjiiJXAUT2oMc6qvqoNnsTefzLZjQP3JfHfSm6YVcieqfe0dKTnKGODFiwqrUaRRf6uVWPvx2WNfs2ceunXoaJiz04l0kydloUBSKrbi9O51vRW6c7+fn91/7BkE1Ju5fKsAqAAAAAElFTkSuQmCC);

}
.capt-tabs .ant-tabs-tab {
  padding: 0;
  color: white;
}

.capt-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus {
  padding: 0;
  color: #c2bebe;
}

.capt-tabs .ant-tabs-ink-bar {
  display: none;
}

.capt-tabs .ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 5px 0 0;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 15px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.not_so_large_card .reset_button {
  top: 110px;
  right: -20px;
  margin-top: 23px;
  height: 33.5px;
  font-size: 15px;
  padding: 2px 12px;
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
  margin-left: 0px;
  margin-right: 0px;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    font-size: 15px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 15%;
  font-size: 15px;
}

.ant-select-arrow {
  height: 13px;
  font-size: 15px;
}

.ant-select-selection-placeholder {
  font-size: 15px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .not_so_large_card {
    max-width: 100%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}


.highlighted-captain-bee-card {
  position: relative;
  border: none; /* Remove the black border completely */
}

.highlighted-captain-bee-card::after {
  content: '';
  position: absolute;
  top: -5px; /* Adjust top to account for the thicker border */
  left: -5px; /* Adjust left to account for the thicker border */
  right: -5px; /* Adjust right to account for the thicker border */
  bottom: -5px; /* Adjust bottom to account for the thicker border */
  border: 5px solid transparent; /* Set the border to a thicker yellow border */
  animation: border-animation 2s linear infinite;
  pointer-events: none;
}

@keyframes border-animation {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #ffb300;
  }
  100% {
    border-color: transparent;
  }
}

/* for Hive Member */

.highlighted-honey-bee-card {
  position: relative;
  border: none; /* Remove the black border completely */
}

.highlighted-honey-bee-card::after {
  content: '';
  position: absolute;
  top: -5px; /* Adjust top to account for the thicker border */
  left: -5px; /* Adjust left to account for the thicker border */
  right: -5px; /* Adjust right to account for the thicker border */
  bottom: -5px; /* Adjust bottom to account for the thicker border */
  border: 5px solid transparent; /* Set the border to a thicker yellow border */
  animation: border-honey-bee-animation 2s linear infinite;
  pointer-events: none;
}

@keyframes border-honey-bee-animation {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #8edf78;
  }
  100% {
    border-color: transparent;
  }
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1111111;
}

.open-button {
  padding: 10px 20px;
  font-size: 16px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111;
}

.custom-modal-content {
  width: 100%;
  max-width: 850px;
  padding: 20px 20px 20px 85px;
  border-radius: 0px;

  position: relative;
  z-index: 1111111;
}

.custom-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}

.modal-logo {
  height: 100px;
  margin-right: 10px;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-row {
  display: flex;
  justify-content: flex-start;
  grid-gap: 70px;
  gap: 70px;
  margin: 30px 0px 50px 0px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-button {
  margin: 0 5px;
  padding: 8px 16px;
  max-width: 200px;
  background: none;
  color: black;
  font-size: 16px;
  border: 1px solid #ffb300;
  border-radius: 0px;
  cursor: pointer;
}

.custom-modal-button:hover {
  background-color: #ffb300;
}

.custom-modal-button-honey {
  margin: 0 5px;
  padding: 8px 16px;
  max-width: 200px;
  background: none;
  color: black;
  font-size: 16px;
  border: 1px solid #8edf78;
  border-radius: 0px;
  cursor: pointer;
}

.custom-modal-button-honey:hover {
  background-color: #8edf78;
}

.highlighted-captain-bee-card {
  position: relative;
  border: none; /* Remove the black border completely */
}

.highlighted-captain-bee-card::after {
  content: '';
  position: absolute;
  top: -5px; /* Adjust top to account for the thicker border */
  left: -5px; /* Adjust left to account for the thicker border */
  right: -5px; /* Adjust right to account for the thicker border */
  bottom: -5px; /* Adjust bottom to account for the thicker border */
  border: 5px solid transparent; /* Set the border to a thicker yellow border */
  animation: border-animation 2s linear infinite;
  pointer-events: none;
}

@keyframes border-animation {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #ffb300;
  }
  100% {
    border-color: transparent;
  }
}

/* for Hive Member */

.highlighted-honey-bee-card {
  position: relative;
  border: none; /* Remove the black border completely */
}

.highlighted-honey-bee-card::after {
  content: '';
  position: absolute;
  top: -5px; /* Adjust top to account for the thicker border */
  left: -5px; /* Adjust left to account for the thicker border */
  right: -5px; /* Adjust right to account for the thicker border */
  bottom: -5px; /* Adjust bottom to account for the thicker border */
  border: 5px solid transparent; /* Set the border to a thicker yellow border */
  animation: border-honey-bee-animation 2s linear infinite;
  pointer-events: none;
}

@keyframes border-honey-bee-animation {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #8edf78;
  }
  100% {
    border-color: transparent;
  }
}

.main-box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.post-box{
    position: relative;
    padding-inline: 50px;
    top: 28px;
    width: 600px;
    background-color: var(--body_background);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.post-text-box {
    color: var(--dark_text);
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    font-size: 25px;
    /* font-family: "Inter"; */
    width: 100%;
    text-align: center;
}

.post-button-box {
    color: var(--dark_text);
    margin-top: 1.2rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    /* font-family: "Inter"; */
    font-size: 13px;
    width: 100%;
}

.button-btn {
    background-color: #FFB300;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    /* border-radius: 0.125rem;  */
    border: 0;
    border-radius: 2px;
    color: var(--dark_text);
    height: 44px;
}

.button-btn:hover {
    background-color: #FFD000;
}

.button-btn-outlined{
    border: 1px solid #FFB300 !important;
    background-color: transparent !important;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    /* border-radius: 0.125rem;  */
    border: 0;
    border-radius: 2px;
    color: var(--dark_text);
    height: 44px;
}

.button-btn-outlined:hover {
    background-color: transparent !important;
    border-color: #FFD000 !important;
}

.close-button-pay {
    position: relative;
    top: -13%;
    /* right: 35%; */
    left: 104%;
    cursor: pointer;
    font-size: 1.25rem;
    color: var(--dark_text);
    z-index: 1000;
}


.pay-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .pay-label {
    margin-bottom: 10px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .radio-button {
    margin-left: 10px;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked{
    color: #ffb300 !important; /* Change this color to your preferred color */
    
  }
  .label-content {
    display: flex;
    align-items: center;
  }
  
  .pay-image {
    width: 120px;
    /* height: 30px; */
    margin-right: 30px;
  }
  
  .pay-description {
    margin: 0;
    font-size: 13px;
  }
  
  .selected {
    border: 2px solid var(--secondary-color); /* Adjust the color as needed */
    border-radius: 4px;
    padding: 5px;
    width: 120px;
  }
  
@media (max-width: 767px) {

  .pay-image {
    width: 60px;
    margin-right: 10px;
  }
  
  .pay-box{
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
}

.pay-text-box {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-top: 1rem;
}

.pay-button-btn {
  font-size: 15px;
  width: 25%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border: 0;
  border-radius: 2px;
}

}
.capt-tabs .ant-tabs-tab {
  padding: 0;
  color: white;
}

.capt-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus {
  padding: 0;
  /* color: #393939; */
}

.capt-tabs .ant-tabs-ink-bar {
  display: none;
}

.capt-tabs .ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 50px 0 0;
  min-width: 125px;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 20px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.large_card .reset_button {
  top: 111px;
  right: -18px;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .large_card {
    max-width: 90%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
  
  .transaction_filters > div > .ant-select {
    width: calc(100%);
  }
}

.site_footer {
  /* background-color: var(--main-body);
    color: var(--body_color); */
  max-width: 1248px;
  width: 100%;
  padding: 24px;
}

.site_footer_inner {
  /* border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color); */
  /* max-width: 80%; */
}

.site_footer_inner > div {
  flex: 1 1;
}

.social-wrapper ul {
  padding-left: 0;
}

.social-wrapper ul li {
  display: inline;
  margin: 0 20px 0 0;
}

.footer_center_text {
  text-align: center;
  font-size: 10px;
  padding: 20px 0 0;
}

.footer-text {
  font-size: 10px;
}

.copyright_bar {
  /* background-color: var(--main-body); */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  max-width: 100%;
}

.row.copyright_bar {
  width: calc(100% + 1.5rem);
}

.flip_icon_container {
  position: relative;
}

.flip_person_icon {
  top: -257px;
  position: absolute;
  /* right: 111px; */
  max-width: 270px;
}

.flip_woman {
  /* top: -279px; */

  top: -212px;
  max-width: 400px;
}

.copyright_text {
  font-size: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 50px;
}

.fit-content {
  width: -moz-fit-content;
  width: fit-content;
  padding-inline: 25px;
  font-size: 10px;
}

.border-right {
  border-right: 1px solid var(--border-color);
}
.fit-content a {
  text-decoration: none;
  color: inherit;
}
.fit-content a:hover {
  color: inherit;
}

.social-connect-icons {
  transition: transform 330ms ease-in-out;
  width: 29px;
  height: 29px;
}

.social-connect-icons:hover {
  transform: scale(1.2);
  transition: transform 330ms ease-in-out;
}

.logo_ind {
  width: 60px;
  transition: transform 330ms ease-in-out;
}

.logo_ind:hover {
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}
.link_sty {
  font-size: 12px;
}
/* .link_sty:hover {
  color: var(--dark_text);
} */

.need_help {
  position: absolute;
  top: -176px;
  right: 32px;
  color: #3ac04c;
  font-size: 23px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 10px 14px 22px;
  cursor: pointer;
}
.need_help_phone {
  position: absolute;
  top: -90px;
  right: 32px;
  color: #3ac04c;
  font-size: 23px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 10px 14px 22px;
  cursor: pointer;
}

.need_help:hover {
  color: #0c7e32;
}
.need_help_phone:hover {
  color: #098d35;
}

@media (max-width: 1347px) {
  .flip_person_icon {
    top: -159px;
    position: absolute;
    right: -10px;
    max-width: 340px;
  }
}

@media (max-width: 1200px) {
  .hide-img {
    display: none;
  }
}

@media (max-width: 767px) {
  .site_footer_inner {
    flex-direction: column;
    padding-left: 0;
    max-width: 100%;
    border: none;
  }

  .site_footer > div > div {
    display: block;
    text-align: center;
    margin-top: 50;
  }

  .site_footer > div > div.flip_icon_container {
    width: 200px;
    height: 200px;
  }

  .mobile-row .btn {
    box-shadow: none !important;
    border: none !important;
    color: inherit;
  }

  .footer-drop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: none !important;
  }

  .mobile-row {
    border-bottom: 1px solid var(--border-color) !important;
  }

  .border-top {
    border-top: 1px solid var(--border-color) !important;
  }

  .copyright_bar {
    max-width: 100%;
  }

  .copyright_text {
    padding-right: 0px;
  }

  .logo_ind {
    width: 110px;
  }

  .desktop-display {
    display: none;
  }

  .flip_person_icon_mobile {
    position: inherit;
    width: 230px;

    top: -60px;
    scale: 1.2;
  }

  .flip_person_icon_mobile.flip_woman {
    top: 26px;
  }

  .footercentre2 p,
  .copyright_text {
    font-size: 10px;
  }
}

@media (min-width: 767px) {
  .mobile-display {
    display: none;
  }
}

.send-box {
  width: 382px;
  border: 1px solid var(--border-color);
  padding: 20px;
  margin-top: 80px;
  border-radius: 16px;
}

.continue-btn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-size: 15px;
  color: #fff;
  border-radius: 2px !important;
  box-shadow: none !important;
  text-transform: none !important;
  height: 40px;
}

.continue-btn:hover,
.continue-btn:focus,
.continue-btn:active {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  box-shadow: none;
}

.continue-outlined-btn {
  border-color: var(--primary-color) !important;
  font-size: 15px;
  color: #282828 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.continue-outlined-btn:hover,
.continue-outlined-btn:focus,
.continue-outlined-btn:active {
  border-color: var(--secondary-color) !important;
  box-shadow: none;
}

.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed,
.MuiSvgIcon-root.MuiStepIcon-root.Mui-active {
  color: var(--primary-color);
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.MuiDrawer-paper{
    background-color: rgba(0, 0, 0, 0.6) !important;
    color: var(--dark-text) !important;
}

.my-drawer{
    z-index: 1000 !important;
    background-color: rgba(var(--overlay), 0.6) !important;
}

.over-bg{
    background-color: rgba(var(--overlay), 0.6) !important;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.over-bg-2{
    background-color: rgba(var(--overlay), 0.7) !important;
}

.over-bg-3{
    background-color: rgba(var(--overlay), 0.7) !important;
}

.main-btn{
    /* background: none; */
    background: transparent !important;

}
.menu-tabs .MuiTab-root.Mui-selected{
    color: var(--dark-text) !important;
}

.menu-tabs .MuiTabs-indicator{
    display: none;
}

.menu-tabs{
    display: flex;
    justify-content: end;
    align-items: end;
}

.menu-tabs .menu-tab{
    font-size: 20px !important;
    text-transform: none;
    text-align: right;
    padding-right: 0;
    display: flex;
    align-items: end;
    color: var(--disabled-btn-text);
}
.my-drawer button{
    background: transparent;
}

.my-drawer button:hover{
    background: transparent;
}

.menu-btns{
    background: #5E5E5E !important;
    color: #fff !important;
    border: 1px solid #5E5E5E !important;
    border-radius: 5px;
    margin-top: 12px;
    width: 148px;
    height: 36px;
    font-size: 13px;
    font-weight: 100;
    text-transform: none !important;
    z-index: 5;
}

.menu-btns:hover{
    background: #3e3e3e !important;
    border-color: #3e3e3e;
}


.main-pay-box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.elite-box{
    position: relative;
    padding-inline: 20px;
    top: 28px;
    width: 600px;
    background-color: var(--body_background);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.elite-text-box {
    color: var(--dark_text);
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    font-size: 25px;
    font-weight: bold;
    /* font-family: "Inter"; */
    width: 100%;
    text-align: center;
}


.elite-button-box {
    color: var(--dark_text);
    margin-top: 1.2rem;
    margin-bottom: 1.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
    /* font-family: "Inter"; */
    font-size: 13px;
}

.elite-button-btn {
    width: 71%;
    background-color: #FFB300;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /* border-radius: 0.125rem;  */
    border: 0;
    border-radius: 2px;
    color: var(--dark_text);
    height: 40px;
    font-size: 14px;
}

.elite-det{
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.button-btn:hover {
    background-color: #FFD000;
}

.elite-close-button-pay {
    position: relative;
    top: -13%;
    /* right: 35%; */
    left: 50%;
    cursor: pointer;
    font-size: 1.25rem;
    color: var(--dark_text);
    z-index: 1000;
}

@media (max-width: 767px) {

.elite-det img{
    width: 150px;
    height: auto !important;
}

.elite-button-box {
  font-size: 10px;
}

.elite-button-btn {
  width: 85%;
  font-size: 11px;
}
  .pay-image {
    width: 60px;
    margin-right: 10px;
  }
  
  .pay-box{
    padding-inline: 10px;
    width: 100vw;
  }

  .close-button-pay {
    top: -13%;
    left: 96%;
    font-size: 1.5rem;
}

.pay-text-box {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-top: 1rem;
}

.pay-button-btn {
  font-size: 15px;
  width: 25%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border: 0;
  border-radius: 2px;
}

}
.main-pay-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.pay-box {
  position: relative;
  padding-inline: 50px;
  padding-bottom: 35px;
  top: 28px;
  max-width: 788px;
  width: 100%;
  padding: 10px 25px 10px 10px;
  background-color: var(--body_background);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.greet-portrait-box {
  position: relative;
  padding-inline: 20px;
  top: 28px;
  width: 400px;
  background-color: var(--body_background);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.close-button-pay {
  position: absolute;
  top: -10px;
  /* right: 35%; */
  right: -30px;
  cursor: pointer;
  font-size: 1.25rem;
  color: var(--dark_text);
  z-index: 1000;
}
.close-button-pay:hover {
  transform: scale(1.1);
}
.close-button-greet {
  position: relative;
  top: -13%;
  /* right: 35%; */
  left: 100%;
  cursor: pointer;
  font-size: 1.25rem;
  color: var(--dark_text);
  z-index: 1000;
}

.close-button-greetl {
  position: relative;
  top: -13%;
  /* right: 35%; */
  left: 104%;
  cursor: pointer;
  font-size: 1.25rem;
  color: var(--dark_text);
  z-index: 1000;
}

.main-head {
  color: var(--dark_text);
  /* padding-bottom: 1.5rem; */
  /* padding-top: 1.5rem; */
  font-size: 30px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.greet-text {
  color: var(--dark_text);
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  font-size: 17px;
  /* font-family: "Inter"; */
  width: 100%;
  text-align: left;
}

.greet-text-portrait {
  color: var(--dark_text);
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  font-size: 17px;
  line-height: 23px;
  /* font-family: "Inter"; */
  width: 100%;
  text-align: left;
}

@media (max-width: 900px) {
  .close-button-pay {
    top: -50px;
    right: 50px;
    transform: translateX(-61px);
  }
}
@media (max-width: 767px) {
  .close-button-greetl {
    left: 100%;
  }

  .close-button-pay {
    top: -50px;
    right: 50px;
    transform: translateX(-21px);
  }

  .pay-box {
    position: relative;
    padding-inline: 20px;
    top: 28px;
    width: 788px;
    background-color: var(--body_background);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }
}

.toolbar-btn{
    background: transparent;
    color: var(--dark_text);
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
}

.toolbar-text{
    font-size: 20px;
    align-self: center;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: none;
}

.rbc-off-range-bg{
    background: var(--calendar-disabled);
}

.rbc-today{
    background-color: var(--cal-bg);
}

.rbc-month-view,
.rbc-header + .rbc-header,
.rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row
{
    border-color: var(--border-color) !important;
}

.rbc-calendar button:hover, 
.rbc-calendar button:focus,
.rbc-calendar button:active
{
    background: transparent !important;
}

.rbc-calendar button:hover,
.rbc-calendar button:focus,
.rbc-calendar button:active{ 
    background: none !important;
    background-color: transparent !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
nav {
  position: fixed;
  z-index: 99;
  width: 100%;

  background: #181a20;
}
nav .wrapper {
  position: relative;

  padding: 0px 100px;
  height: 70px;
  line-height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a {
  text-decoration: none;
}
.wrapper .logo a img {
  transition: all 0.2s linear;
}
.wrapper .logo a img:hover {
  transform: scale(1.1);
}
.wrapper .nav-links {
  display: inline-flex;
  margin: 0;
}
.nav-links li {
  list-style: none;
}

.nav-links li.main {
  padding: 9px 15px;
}
.nav-links li a {
  color: #bfbfbf;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  /* padding: 9px 15px; */

  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover {
  /* background: #3a3b3c; */
  color: #fff;
}
.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.2s ease;
  /* top: 70px; */
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
}
.nav-links li:hover .backdrop {
  display: block;
  visibility: visible;
  opacity: 1;
}
.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
  top: 70px;
  z-index: -1; /* Set z-index below .mega-box */
  display: none; /* Initially hidden */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transition: opacity 0.3s ease;
  transition-delay: 0.2s; /* Add a delay of 0.2 seconds */
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  background: #161617;
  height: auto;
  z-index: 1111111;
  padding: 0 30px 50px 0;
  top: 70px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease;
  /* top: 70px; */
  transition-delay: 0.3s;
}
.profile-inner-item-border-bottom {
  opacity: 0;
}
.profile-list-item:hover .profile-inner-item-border-bottom {
  opacity: 1;
}
.active-wallet {
  opacity: 1;
}

.mega-box .content {
  /* background: white; */
  /* max-width: 1200px;
  margin: auto; */
  height: auto;

  padding: 25px 20px;
  padding-left: 210px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); */
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 35px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header {
  font-size: 12px;
  font-weight: 500;
}
.content .row .mega-links {
  /* margin-left: -40px; */
  /* border-left: 1px solid rgba(255, 255, 255, 0.09); */
}
.row .mega-links li {
  /* padding: 0 20px; */
}

.row .mega-links li a {
  padding: 0px;
  /* padding: 0 20px; */
  color: #d9d9d9;
  font-size: 12px;
  display: block;
}
.dark_color {
  color: #434343 !important;
}
.dark_color:hover {
  color: #2d2d2d !important;
}
.row .mega-links.main li a {
  padding: 0px;
  /* padding: 0 20px; */
  color: #d9d9d9;
  font-size: 20px;
  font-weight: 600;
  display: block;
}
.row .mega-links li a:hover {
  color: #f2f2f2;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.secondary_header_root {
  position: fixed;
  top: 70px;
  background: white;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  width: 100%;
  z-index: 1000;
}
.secondary_header_content {
  width: 100%;
  max-width: 1900px;
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: stretch;
}
.secondary_header_content_item {
  display: flex;
  min-width: 145px;
  margin: 0px 2.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.secondary_header_content_item:hover,
.secondary_header_content_item.active {
  border-bottom: 2px solid grey;
}
.secondary_header_content_img_container {
  width: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 05px;
}

.secondary_header_content_img_container img {
  height: 32px;
}

.link_active {
  color: white !important;
  font-size: 13px !important;
}
@media screen and (max-width: 1310px) {
  nav .wrapper {
    padding: 0 30px;
  }
  .wrapper .btn {
    display: block;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 1;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #Buy\ Crypto:checked ~ .mega-box,
  #Lotto:checked ~ .mega-box,
  #Meme:checked ~ .mega-box,
  #Hive:checked ~ .mega-box,
  #xTokens:checked ~ .mega-box,
  #Academy:checked ~ .mega-box,
  #Swap:checked ~ .mega-box,
  #Shop:checked ~ .mega-box,
  #WallStreet:checked ~ .mega-box,
  #Affiliate:checked ~ .mega-box,
  #Elite\ Club:checked ~ .mega-box,
  #Crypto\ Treasury:checked ~ .mega-box,
  #Company:checked ~ .mega-box,
  #profile:checked ~ .mega-box,
  #Exchange:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: #3a3b3c;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }
  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li {
    margin: 0;
  }
  .content .row header {
    font-size: 19px;
  }
  .secondary_header_content {
    justify-content: flex-start;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}

.navbar-dark {
  --bs-navbar-color: #fff;
  --bs-navbar-hover-color: #c8c5c5;
  /* --bs-navbar-active-color: #c8c5c5; */
  --bs-nav-link-font-size: 12px;
  --bs-navbar-nav-link-padding-x: 1rem;
  --bs-navbar-padding-y: 0.8rem;
}

.dark-menu .dropdown-item {
  --bs-dropdown-link-hover-bg: rgba(211, 211, 211, 0.42);
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-link-active-bg: rgba(211, 211, 211, 0.42);
  --bs-dropdown-item-padding-y: 0.45rem;
}

.dropdown-menu {
  --bs-dropdown-bg: var(--body_background) !important;
}

.navbar.bg-dark {
  background-color: #000 !important;
  /* background-image: url('../../assets/arts/header\ new\ year.svg'); */
  height: 90px;
}

.navbar button {
  height: 36px;
  font-size: 1rem;
}

.elipse-img {
  position: relative;
  width: 57px;
  height: 59px;
  background-color: transparent;
  clip-path: ellipse(30% 50% at 50% 50%);
  transform: rotate(30deg);
  /* margin: 0 auto; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.elipse-img img {
  display: block;
  width: 50%;
  height: 50%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

/* .navbar-collapse.show {
    padding: calc(var(--bs-gutter-y) * .5) calc(var(--bs-gutter-x) * .5);
}
*/

a.nav-link:hover {
  color: var(--dropdown-hover) !important;
}

.dark-menu .dropdown-menu {
  background-color: #171717;
}

.dark-menu .dropdown-item {
  --bs-dropdown-link-color: #fff;
}

.dark-menu .dropdown-toggle::after {
  vertical-align: 0.05em;
  margin-left: 0.3em;
}

.main-header {
  background-color: transparent;
  position: relative;
  color: white;
  padding: 25px 90px 0 90px;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  width: 100%;
  background-image: url(/static/media/headerBG.bb5ab19f.png);
}

.app-link {
  font-family: AvenirRegular, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
  margin: 0 25px;
  padding: 15px 0 10px;
}

.app-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.td-none {
  text-decoration: none !important;
}

.dropdown-toggle.nav-link:hover ~ .dropdown-menu,
.dropdown-menu:hover {
  display: block;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0;
}

.header-connect-wallet:hover {
  /* box-shadow: #ffffff85 0 5px 7px 0;
    transform: scale(1.05); */
  background-color: var(--secondary-color);
}

.header-connect-wallet {
  width: 200px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--primary-color);
  padding: 15px;
  border-radius: 7px;
}

.logo {
  color: #fff;
}

.ant-btn.btn_xl {
  height: 55px;
  border-radius: 10px;
  font-size: 20px;
}

.ant-btn-primary {
  background-color: #006dff;
  border-color: #006dff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #00387d;
  border-color: #00387d;
}

.logo__icon {
  background-image: url("/static/media/indexx.ai white.3e19de4b.png");
  font-size: 0;
  background-repeat: no-repeat;
  width: 119px;
  height: 36px;
  background-size: contain;
  transition: transform 330ms ease-in-out;
}

.logo__icon:hover {
  background-image: url("/static/media/indexx.ai white.3e19de4b.png");
  background-repeat: no-repeat;
  width: 119px;
  height: 36px;
  background-size: contain;
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

.hive_icon {
  background-image: url("/static/media/indexx ai yellow 1.d46df3f1.svg");
  font-size: 0;
  background-repeat: no-repeat;
  width: 119px;
  height: 36px;
  background-size: contain;
  transition: transform 330ms ease-in-out;
}

.hive_icon:hover {
  background-image: url("/static/media/indexx ai yellow 1.d46df3f1.svg");
  background-repeat: no-repeat;
  width: 119px;
  height: 36px;
  background-size: contain;
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}
.logo__text {
  font-size: 29px;
  line-height: 1;
  color: #fff;
  padding-right: 10px;
}

.logo__text:hover {
  color: #b9b9b9;
}

.cursor-pointer {
  cursor: pointer;
}

.font_10x {
  font-size: 10px;
}

.font_15x {
  font-size: 15px;
}

.font_18x {
  font-size: 18px;
}

.font_20x,
.default-link.font_20x {
  font-size: 20px;
  color: var(--body_color);
}

.padding-l-2x {
  padding-left: 20px;
}

.padding-lr-1x {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-lr-2x {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-t-0 {
  padding-top: 0;
}

.padding-2x {
  padding: 20px;
}

.padding-l-1x {
  padding-left: 10px;
}

.padding-t-1x {
  padding-top: 10px;
}

.padding-t-2x {
  padding-top: 20px;
}

.padding-t-3x {
  padding-top: 30px;
}

.padding-l-3x {
  padding-left: 30px;
}

.padding-b-2x {
  padding-bottom: 20px;
}

.padding-b-3x {
  padding-bottom: 30px;
}

.padding-r-1_x {
  padding-right: 15px;
}

.primary_color {
  color: #006dff;
}

.header_connect_wallet.ant-btn {
  font-size: 15px;
  height: 40px;
  max-width: 180px;
}

.text-extra-small {
  font-size: 10px;
}

.text-center {
  text-align: center;
}

.position-relative {
  position: relative;
}

.d-block {
  display: block;
}

.swap_container {
  background-position: top 90px center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ant-btn.buy_sell_button {
  background-color: var(--primary-color);
  font-size: 15px;
  height: 40px;
}

.text-white {
  color: #fff !important;
}

a.link.text-white:hover {
  color: var(--dropdown-hover) !important;
}

.buy-cryp a.nav-link:hover {
  color: var(--link-hover) !important;
}

.main_navigation .nav-link {
  color: #fff;
}

/* .text-white:hover,
.main_navigation .nav-link:hover {
    color: #C8C5C5 !important;
} */

/* 
.main_navigation {
    padding-top: 14px;
} */
.index_page_nav {
  position: relative;
  /* top: 50%;
    transform: translateY(-50%); */
}

.main_navigation .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #fff;
}

.main_navigation .ant-dropdown-menu-item:hover {
  background-color: rgba(211, 211, 211, 0.42);
  color: #fff;
}

.main_navigation.ant-dropdown-menu {
  background-color: #171717;
}

.disable_icon {
  opacity: 0.8;
  pointer-events: none;
}

.primary_hover:hover {
  color: #00387d;
}

.index_logo {
  padding-right: 10px;
}

.color_general {
  color: var(--body_color);
}

.width_auto {
  width: auto;
}

.margin-r-1x {
  margin-right: 10px;
}

.margin-r-2x {
  margin-right: 20px;
}

.light_button {
  padding: 6px 15px 10px 15px;
  border: 1px solid var(--primary-color);
  border-radius: 3px;
}

.light_button:hover,
.light_button:active,
.light_button:focus {
  border: 1px solid var(--secondary-color);
}

.flex_1 {
  flex: 1 1;
}

.arrow_address {
  cursor: pointer;
  opacity: 0.6;
}

.arrow_address:hover {
  cursor: pointer;
  opacity: 1;
}

.hover_icon {
  cursor: pointer;
  opacity: 0.8;
}

.hover_icon:hover {
  cursor: pointer;
  opacity: 1;
}

.color-warn {
  color: var(--primary-color);
}

.ant-btn.buy_crypto_btn {
  background-image: url(/static/media/arrowSwapIcon.57335994.svg);
  background-repeat: no-repeat;
  background-position: left 10px center;
  padding-left: 30px;
}

.ant-btn.buy_crypto_btn:hover,
.ant-btn.buy_crypto_btn:focus,
.ant-btn.buy_crypto_btn:active {
  background-image: url(/static/media/arrowSwapIcon.57335994.svg) !important;
  background-repeat: no-repeat !important;
  background-position: left 10px center !important;
}

.loader {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  display: none;
}

.loader > img {
  text-align: center;
  margin-top: calc(50vh - 100px);
  width: 100px;
}

button.navbar-toggler {
  width: auto;
}

@media (max-width: 767px) {
  .index_page_nav {
    justify-content: end;
  }

  .main-header {
    padding: 0 0 !important;
  }

  .navbar {
    width: 100%;
  }

  .hamburger-icon {
    display: flex;
    justify-content: flex-end;
  }

  /* .navbar-collapse {
        background-color: rgba(0, 0, 0, 1);
        padding-left: 15px;
        border-radius: 5px;
        min-height: 400px;
    } */

  .text-white,
  .nav-link {
    color: #fff !important;
  }

  .logo__holder {
    align-items: flex-end;
    margin-left: 0 !important;
  }

  .logo__text {
    font-size: 20px;
  }

  .navbar-collapse {
    background-color: #000;
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .navbar.bg-dark {
    height: 60px;
  }

  .navbar > .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .main-div {
    width: 100% !important;
  }

  .my-menu .dropdown-menu {
    width: auto;
    margin-left: 0 !important;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }

  .main-menu {
    padding: 0 !important;
    padding-left: 0 !important;
    flex-direction: column !important;
    overflow-y: scroll;
  }

  .back {
    display: none;
  }
}

.link-div {
  padding-left: 0;
  background: transparent !important;
  font-size: 24px;
  /* color: #333336; */
  color: var(--body_color);
  font-weight: 600;
}

.link-style {
  font-size: 24px;
  /* color: #333336; */
  color: var(--body_color);
  background: transparent;
  font-weight: 600;
}

.link-style :hover,
.link-style :focus,
.link-style :active {
  background: transparent;
}

.action-link-div {
  padding-left: 0;
  background: transparent !important;
  font-size: 12px;
  font-weight: 400;
  /* font-family: 'SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif'; */
  color: rgb(134, 134, 139);

  /* color: var(--body_color); */
}

.action-link-style {
  font-size: 12px;
  font-weight: 600;
  /* color: #333336; */
  color: var(--body_color);
  background: transparent;
}

.action-link-style :hover {
  background: transparent;
}

.main-div {
  width: 200vw;
}

.black-div {
  height: 26px;
  /* background: black;
  background-image: url('../../assets/arts/christmas_bg.png');
  object-fit: contain; */
}

.back {
  position: absolute;
  top: 405px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); Black color with 50% opacity */
  z-index: 999;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  height: 100vh;
}

.back:hover {
  display: none;
}

.link-div:active + .dropdown-menu {
  display: none;
}

.my-menu .dropdown-menu {
  /* width:  calc(100vw - 17px); */
  width: auto;
  /* max-width: 1320px; */
  margin-left: -255px;
  padding-top: 0;
  border-radius: 0;
  border: none;
  /* justify-content: flex-start; */
  /* align-items: start; */
  /* top: 65px; */
  background: transparent;
}

.main-menu {
  padding: 30px;
  padding-left: 150px;
  background-color: var(--body_background);
}
.prod-menu .dropdown-menu {
  margin-left: -360px;
}

.prod-menu .dropdown-menu .prod-back {
  top: 605px;
}

.prog-menu .dropdown-menu {
  margin-left: -461px;
}

.prog-menu .dropdown-menu .prog-back {
  top: 362px;
}

.wallet-menu .dropdown-menu {
  margin-left: -561px;
}

.wallet-menu .dropdown-menu .wallet-back {
  top: 210px;
}

.comp-menu .dropdown-menu {
  margin-left: -645px;
}

.comp-menu .dropdown-menu .comp-back {
  top: 402px;
}

.profile-menu .dropdown-menu {
  margin-left: -77vw;
}
.profile-menu .main-menu {
  padding-left: 15vw;
}

.profile-menu .dropdown-menu .profile-back {
  top: 665px;
}

.profile-menu .dropdown-toggle::after {
  color: var(--primary-color);
}

@media screen and (min-width: 2500px) {
  .my-menu .dropdown-menu {
    width: auto;
    margin-left: -1005px;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }

  .main-menu {
    padding-left: 894px;
  }

  .profile-menu .main-menu {
    padding-left: 30vw;
  }

  .prod-menu .dropdown-menu {
    margin-left: -1108px;
  }

  .prog-menu .dropdown-menu {
    margin-left: -1208px;
  }

  .wallet-menu .dropdown-menu {
    margin-left: -1305px;
  }

  .comp-menu .dropdown-menu {
    margin-left: -1388px;
  }

  .profile-menu .dropdown-menu {
    margin-left: -62.8vw;
  }
}

@media screen and (min-width: 2200px) and (max-width: 2500px) {
  .my-menu .dropdown-menu {
    width: auto;
    margin-left: -625px;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }

  .main-menu {
    padding-left: 516px;
  }

  .profile-menu .main-menu {
    padding-left: 28vw;
  }

  .prod-menu .dropdown-menu {
    margin-left: -730px;
  }

  .prog-menu .dropdown-menu {
    margin-left: -831px;
  }

  .wallet-menu .dropdown-menu {
    margin-left: -931px;
  }

  .comp-menu .dropdown-menu {
    margin-left: -1012px;
  }

  .profile-menu .dropdown-menu {
    margin-left: -67.4vw;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2200px) {
  .my-menu .dropdown-menu {
    width: auto;
    margin-left: -499px;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }

  .main-menu {
    padding-left: 390px;
  }

  .profile-menu .main-menu {
    padding-left: 25vw;
  }

  .prod-menu .dropdown-menu {
    margin-left: -605px;
  }

  .prog-menu .dropdown-menu {
    margin-left: -705px;
  }

  .wallet-menu .dropdown-menu {
    margin-left: -805px;
  }

  .comp-menu .dropdown-menu {
    margin-left: -891px;
  }

  .profile-menu .dropdown-menu {
    margin-left: -69.6vw;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .my-menu .dropdown-menu {
    width: auto;
    margin-left: -435px;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }

  .main-menu {
    padding-left: 328px;
  }

  .profile-menu .main-menu {
    padding-left: 23vw;
  }

  .prod-menu .dropdown-menu {
    margin-left: -540px;
  }

  .prog-menu .dropdown-menu {
    margin-left: -640px;
  }

  .wallet-menu .dropdown-menu {
    margin-left: -741px;
  }

  .comp-menu .dropdown-menu {
    margin-left: -825px;
  }

  .profile-menu .dropdown-menu {
    margin-left: -70.9vw;
  }
}

@media screen and (min-width: 1650px) and (max-width: 1800px) {
  .my-menu .dropdown-menu {
    width: auto;
    margin-left: -330px;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }

  .main-menu {
    padding-left: 222px;
  }

  .prod-menu .dropdown-menu {
    margin-left: -436px;
  }

  .prog-menu .dropdown-menu {
    margin-left: -539px;
  }

  .wallet-menu .dropdown-menu {
    margin-left: -638px;
  }

  .comp-menu .dropdown-menu {
    margin-left: -723px;
  }

  .profile-menu .main-menu {
    padding-left: 23vw;
  }

  .profile-menu .dropdown-menu {
    margin-left: -73.6vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .my-menu .dropdown-menu {
    width: auto;
    margin-left: -330px;
    padding-top: 0;
    border-radius: 0;
    border: none;
  }

  .main-menu {
    padding-left: 222px;
  }

  .prod-menu .dropdown-menu {
    margin-left: -436px;
  }

  .prog-menu .dropdown-menu {
    margin-left: -539px;
  }

  .wallet-menu .dropdown-menu {
    margin-left: -638px;
  }

  .comp-menu .dropdown-menu {
    margin-left: -723px;
  }

  .profile-menu .main-menu {
    padding-left: 120px;
  }

  .profile-menu .dropdown-menu {
    margin-left: -800px;
  }
}
.profile-menu .dropdown-toggle.nav-link {
  display: flex;
  justify-content: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  padding-bottom: 0;
  padding-left: 0;
}

a:hover {
  color: var(--dropdown-hover);
}

.app-head {
  background-color: #000 !important;
  /* background-image: url('../../assets/arts/header\ new\ year.svg'); */
}

.side-panel {
  width: 200px;
  transition: transform 0.3s ease-in-out;
}

.side-panel.open {
  transform: translateX(0);
}

.side-panel.closed {
  transform: translateX(-200px);
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
}

.mob-head button {
  background: transparent;
}
.my-pap button,
.sec-head button {
  background: transparent;
  color: var(--body_color) !important;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 95%;
}

.mob-head button:hover,
.my-pap button:hover {
  background: transparent;
}

.main-list {
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 1.1428571429 !important;
  letter-spacing: 0.007em !important;
}

.second-list {
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 1.1428571429 !important;
  letter-spacing: 0.007em !important;
}

.act-list {
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  letter-spacing: inherit !important;
}

.css-1ps6pg7-MuiPaper-root {
  background-color: var(--main-body) !important;
  color: var(--body_color) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--body_background);
}

.dark_mode {
  /* margin-top: -67px; */
  /* margin-left: 10px; */
  width: 22px;
  height: 22px;
  margin-left: 20px;
}

.dark_mode_label {
  position: absolute;
  width: 22px;
  height: 22px;
  /* display: block; */
  background: #ebebeb;
  border-radius: 200px;
  /* box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4); */
  cursor: pointer;
  /* transition: 0.3s; */
}

.dark_mode_label:after {
  content: '';
  width: 22px;
  height: 22px;
  position: absolute;
  /* top: 3px; */
  /* left: 2px; */
  /* background: linear-gradient(180deg, #ffcc89, #d8860b); */
  /* background: linear-gradient(180deg,#e8dfdf, #adadad); */
  background-image: url("/static/media/dark 2.5090370f.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background-image: url("/static/media/light 2.35e4658b.svg");
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  /* left: 42px; */
  /* transform: translateX(-100%); */
  /* background: linear-gradient(180deg, #777, #3a3a3a); */
  background-image: url("/static/media/light 2.35e4658b.svg");
  background-size: contain;
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}

/* @media screen and (max-width: 768px) {
    .dark_mode {
        margin-top: -28px;
    }
} */

.indexx_hive_membership_root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 100px auto;
  text-align: center;
}

.indexx_hive_membership_root h2 {
  font-weight: bold;
  font-size: 45px;
}

.indexx_hive_membership_root h4 {
  font-size: 18px;
}

.indexx_hive_membership_root p {
  font-size: 12px;
}

.power-pack-category {
  display: flex;
  padding: 20px;
  width: 100%;
  max-width: 950px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto 100px auto;
}
.power-pack-category div {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;
  padding: 10px;
}
.orange-btn {
  background: #f8c229;
}
.orange-btn:hover {
  background-color: #ffd000;
}
.yellow-btn {
  background: #8edf78;
}
.yellow-btn:hover {
  background-color: #45c921;
}
.power-pack-category div h3 {
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}
.power-pack-category div p {
  margin: 20px 0 0 0;
  text-align: center;
  font-size: 12px;
}
.power-pack-category div img {
  width: 100%;
}
.power-pack-category div button {
  margin-top: 50px;
  border-radius: 0;
  font-size: 16px;
  color: #000;
  width: 100%;
}
.power-pack-category div button:first-child:hover {
  background-color: blue;
}
.power-pack-table {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  max-width: 1100px;
  margin: 0px auto 100px auto;
}

.power-pack-table img {
  width: 100%;
}

.btn-hover-class {
  height: 55px;
  background: #ffb300;
  color: #000;
  font-size: 20px;
  border-radius: 0px;
}

.btn-hover-class:hover {
  background: #ffd000;
}


.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 5px 0 0;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 15px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters > div {
  flex: 1 1;
}

.transaction_filters > div > .ant-select {
  width: calc(100% - 20px);
}

.not_so_large_card .reset_button {
  top: 110px;
  right: -20px;
  margin-top: 23px;
  height: 33.5px;
  font-size: 15px;
  padding: 2px 12px;
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
  margin-left: 0px;
  margin-right: 0px;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    font-size: 15px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  top: 15%;
  font-size: 15px;
}

.ant-select-arrow {
  height: 13px;
  font-size: 15px;
}

.ant-select-selection-placeholder {
  font-size: 15px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .not_so_large_card {
    max-width: 100%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}


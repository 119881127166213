:root {
  --main-body: #f4f4f6;
  --body_background: #f4f4f6;
  --body_color: #333336;
  --dark_text: #000;
  --err-clr: rgba(246, 96, 54, 0.2);
  --light-gr-color: rgba(18, 18, 18, 0.4);
  --disabled-btn: #f5f5f5;
  --border-color: #5f5f5f36;
  --conf-purchase: rgba(96, 96, 96, 0.5);
  --dash-bg: #f6f6f6;
  --hive-honeybee: #e7e7e7;
  --calendar-disabled: #e6e6e6;
  --cal-bg: #eaf6ff;
  --link_color: #2607f3;
  --table_header: #939292;
  --table-border: #e6e8ea;
  --section3-unselected: rgba(0, 0, 0, 0.15);
  --blog-border-orange: rgba(246, 96, 54, 0.2);
  --market-table-color: #fff;

  --background-image: url('./assets/arts/Black_withdraw.svg');

  --disabled-btn-text: rgba(0, 0, 0, 0.25);
  --timeline: #e0e0e0;

  --primary-color: #11be6a;
  --secondary-color: #0ea55a;
  --highlight-color: lightgreen;
  --link-hover: #1890ff;
  --dropdown-hover: #1890ff;
  --staking-color: #e7e7e7;
  --table-light: #eeeeee;
  --scroller: 193, 193, 193;
  --overlay: 175, 175, 175;
}

[data-bs-theme='dark'] {
  --main-body: #181a21;
  --body_background: #171717;
  --body_color: #e8e8ed;
  --dark_text: #fff;
  --err-clr: rgba(246, 96, 54, 0.5);
  --light-gr-color: rgba(226, 220, 220, 0.5);
  --disabled-btn: #3231319f;
  --border-color: #2c2c2c;
  --conf-purchase: rgba(212, 212, 212, 0.685);
  --dash-bg: #151414;
  --hive-honeybee: #aeaeae;
  --timer-color: #2d2d2d;
  --calendar-disabled: #2c2c2c;
  --cal-bg: #263e51;

  --link_color: #f307b8;
  --table_header: #aeaeae;
  --table-border: #3c3b3b;
  --section3-unselected: rgba(255, 255, 255, 0.25);
  --blog-border-orange: rgba(59, 130, 246, 0.3);
  --market-table-color: #575757;
  --background-image: url('./assets/arts/Grey_withdraw.svg');

  --disabled-btn-text: rgba(135, 132, 132, 0.785);
  --timeline: #3231319f;

  --primary-color: #11be6a;
  --secondary-color: #0ea55a;
  --highlight-color: lightgreen;
  --link-hover: #1890ff;
  --dropdown-hover: #1890ff;
  --staking-color: #171c26;
  --table-light: #040404;
  --scroller: 46, 46, 46;
  --overlay: 0, 0, 0;
}

@font-face {
  font-family: AvenirLight;
  src: url(./assets/fonts/AvenirLight.ttf) format('truetype');
}

@font-face {
  font-family: AvenirRegular;
  src: url(./assets/fonts/AvenirRoman.ttf) format('truetype');
}

@font-face {
  font-family: AvenirMedium;
  src: url(./assets/fonts/AvenirMedium.ttf) format('truetype');
}

@font-face {
  font-family: AvenirHeavy;
  src: url(./assets/fonts/AvenirBlack.ttf) format('truetype');
}

html {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-body) !important;
  color: var(--body_color) !important;
  padding-right: 0 !important;
  overflow: auto !important;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--body_color) !important;
}

.flex-align-start {
  align-items: start;
}

div.flex-align-center {
  align-items: center;
}

div.flex-align-stretch {
  align-items: stretch;
}

div.flex-justify-center {
  justify-content: center;
}

.mb-0 {
  margin-bottom: 0;
}

.card {
  min-width: 440px;
  background-color: transparent;
  color: var(--body_color);
  border: 1px solid rgba(0, 109, 255, 0.5);
  min-height: 600px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.card__header {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 109, 255, 0.5);
}

.card__header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__header__inner__left {
  flex: 1 1;
}

.card__header__inner__right {
  display: flex;
  flex: 1 1 0px;
  justify-content: flex-end;
}

.card__body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card__footer {
  text-align: center;
  padding: 20px;
}

.card__title {
  color: #5f5f5f;
  font-size: 40px;
  margin-bottom: 0;
  flex: 1 1;
  line-height: 1.2;
}

.orange.card {
  border: #0ea55a;
}

.tag {
  font-size: 1em;
  font-family: AvenirHeavy;
  border-radius: 10px;
  padding: 5px 10px 2px;
  background-color: #f2f2f2;
  color: #5f5f5f;
}

.input__field {
  border: 1px solid rgba(0, 109, 255, 0.5);
  border-radius: 5px;
  height: 56px;
  line-height: 60px;
}

.input__field input {
  text-align: right;
  font-size: 25px;
  padding-right: 30px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.index_tokens img {
  width: 176px;
  height: 176px;
}

.font_25x {
  font-size: 25px;
}

@media (max-width: 767px) {
  .card {
    min-width: -webkit-fill-available;
  }
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  color: var(--body_color);
  background-color: transparent !important;
  border: none;
}

.ant-pagination-item-active {
  color: var(--dark_text) !important;
  font-weight: bolder;
  background-color: transparent !important;
  font-size: 16px;
}

.ant-pagination-item-link,
.ant-pagination-item-ellipsis {
  background-color: transparent !important;
  color: var(--body_color) !important;
  border: none !important;
}

.tabs_button .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border: 1px solid var(--body_background);
  border-radius: 5px;
}

.custom_table .ant-table-content::-webkit-scrollbar {
  height: 7px !important;
}
.custom_table .ant-table-content::-webkit-scrollbar-thumb {
  background-color: #5f6673 !important;
  border-radius: 4px;
}
.custom_table .ant-table-content::-webkit-scrollbar-track {
  background-color: #35393f !important;
}
.custom_table .ant-table-content::-webkit-scrollbar-thumb:hover {
  background-color: #484f59 !important;
}
.reset-button {
  background: none !important;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 50px 0 0;
  min-width: 125px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 50px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #5f5f5f;
  font-size: 20px;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.tabs_button.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.tabs_button
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  border: none;
  background: transparent;
}

.transaction_filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-start;
}

.filter-item {
  flex: 1 1 calc(16% - 10px); /* 25% width for 4 items per row */
  min-width: calc(16% - 10px);
}

@media (max-width: 1024px) {
  .filter-item {
    flex: 1 1 calc(50% - 10px); /* 50% width for 2 items per row on tablets */
    min-width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .filter-item {
    flex: 1 1 100%; /* 100% width for 1 item per row on mobile */
    min-width: 100%;
  }
}
.large_card .reset_button {
  top: 111px;
  right: -18px;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .transaction_crypto_history th {
    display: none;
  }
  .large_card {
    max-width: 90%;
  }

  .transaction_crypto_history .ant-table-tbody > tr > td:last-child {
    text-align: end;
  }
  .transaction_crypto_history .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }
  .tabs_button
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    min-width: 97px;
  }
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1111111;
}

.open-button {
  padding: 10px 20px;
  font-size: 16px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111;
}

.custom-modal-content {
  width: 100%;
  max-width: 850px;
  padding: 20px 20px 20px 85px;
  border-radius: 0px;

  position: relative;
  z-index: 1111111;
}

.custom-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}

.modal-logo {
  height: 100px;
  margin-right: 10px;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-row {
  display: flex;
  justify-content: flex-start;
  gap: 70px;
  margin: 30px 0px 50px 0px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-button {
  margin: 0 5px;
  padding: 8px 16px;
  max-width: 200px;
  background: none;
  color: black;
  font-size: 16px;
  border: 1px solid #ffb300;
  border-radius: 0px;
  cursor: pointer;
}

.custom-modal-button:hover {
  background-color: #ffb300;
}

.custom-modal-button-honey {
  margin: 0 5px;
  padding: 8px 16px;
  max-width: 200px;
  background: none;
  color: black;
  font-size: 16px;
  border: 1px solid #8edf78;
  border-radius: 0px;
  cursor: pointer;
}

.custom-modal-button-honey:hover {
  background-color: #8edf78;
}

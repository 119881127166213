@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .loading-spinner {
    border: 1px solid;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("../../assets/arts/loaderIcon.gif") center / 50px no-repeat;
  }
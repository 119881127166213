.bs_container.medium_card {
    min-width: 700px;
}

.medium_card .light_button, .light_button, .light_button:hover, .light_button:focus, .light_button:active {
    padding: 6px 15px 10px 40px !important;
    background-repeat: no-repeat;
    background-position: left 10px center;
}

.deposit_btn,
.deposit_btn:hover,
.deposit_btn:focus,
.deposit_btn:active {
    background-image: url("../../assets/arts/depositIcon.svg");
}

.deposit_btn{
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.withdraw_btn{
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.deposit_btn:hover,
.deposit_btn:focus,
.deposit_btn:active
{
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.withdraw_btn,
.withdraw_btn:hover,
.withdraw_btn:focus,
.withdraw_btn:active {
    background-image: var(--background-image);
    background-color: var(--body_background);
    background-size: 17px;
    color: var(--body_color) !important;
}

.send_btn,
.send_btn:hover,
.send_btn:focus,
.send_btn:active {
    background-image: url("../../assets/arts/rocketIcon.svg");
    background-color: var(--body_background);
    color: var(--body_color) !important;
}

.sell_screens .bs_token {
    padding: 12px 20px;
}

/* 
.sell_screens .bs_footer_action button {
    background-color: #00A151;
    border-color: #00A151;
} */
/* 
.sell_screens .bs_footer_action button:hover,
.sell_screens .bs_footer_action button:focus,
.sell_screens .bs_footer_action button:active {
    background-color: #026333 !important;
} */
/* 
.sell_screens .bs_footer_action button.disable_icon:hover,
.sell_screens .bs_footer_action button.disable_icon:focus,
.sell_screens .bs_footer_action button.disable_icon:active {
    background-color: #00A151 !important;
}

.sell_screens button.sell_btn {
    background-color: #00A151;
    border-color: #00A151;
} */

.font_w_800 {
    font-weight: 800;
}

.margin-t-3x {
    margin-top: 30px;
}


.margin-t-2x {
    margin-top: 20px
}

.margin-t-auto {
    margin-top: auto;
}

.buy_purchase_modal .ant-modal-title {
    font-size: 0;
    /* background-image: url("../../assets/arts/indexGreyLogoIcon.svg"); */

    background-image: url("../../assets/arts/indexBlueLogoIcon.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: auto;
    /* background-color: var(--body_background) !important; */
}

.ant-modal-header{
    background-color: var(--body_background) !important;
    border-color: var(--border-color);
}

.ant-select-dropdown, .ant-select-selector, .ant-select {
    background-color: var(--body_background) !important;
}


.bs_token_left:hover .ant-select-dropdown:not(:hover) 
{
    background-color: var(--body_background) !important;

}
.ant-select-item-option-selected {
    background-color: var(--disabled-btn) !important;
    color: var(--body_color); 
}


.common__token {
    color: var(--body_color);
    background-color: var(--body_background);
    border-color: var(--border-color);
  }
  
  .common__token:hover {
    color: var(--body_color);
    background-color: var(--disabled-btn);
}

.custom-modal .ant-modal-close-x {
  color: var(--body_color); /* Your desired color */
}
  
.custom-modal .ant-modal-footer{
    color: var(--body_color);
    background-color: var(--body_background);  
    border-color: var(--border-color);
}
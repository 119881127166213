.card.card-s {
    min-height:330px;
    padding:19px;
}
    



































































.my-tabs .ant-tabs-tab {
    padding: 0;
    color: #878787 !important;
}


.my-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus {
    padding: 0;
    color: #000;
}

.my-tabs .ant-tabs-ink-bar {
    /* background:  #000; */
    display: none;
  }

  .my-tabs .ant-tabs-top > .ant-tabs-nav::before{
    display: none;
  }
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
nav {
  position: fixed;
  z-index: 99;
  width: 100%;

  background: #181a20;
}
nav .wrapper {
  position: relative;

  padding: 0px 100px;
  height: 70px;
  line-height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a {
  text-decoration: none;
}
.wrapper .logo a img {
  transition: all 0.2s linear;
}
.wrapper .logo a img:hover {
  transform: scale(1.1);
}
.wrapper .nav-links {
  display: inline-flex;
  margin: 0;
}
.nav-links li {
  list-style: none;
}

.nav-links li.main {
  padding: 9px 15px;
}
.nav-links li a {
  color: #bfbfbf;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  /* padding: 9px 15px; */

  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover {
  /* background: #3a3b3c; */
  color: #fff;
}
.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.2s ease;
  /* top: 70px; */
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
}
.nav-links li:hover .backdrop {
  display: block;
  visibility: visible;
  opacity: 1;
}
.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
  top: 70px;
  z-index: -1; /* Set z-index below .mega-box */
  display: none; /* Initially hidden */
  backdrop-filter: blur(10px);
  transition: opacity 0.3s ease;
  transition-delay: 0.2s; /* Add a delay of 0.2 seconds */
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  background: #161617;
  height: auto;
  z-index: 1111111;
  padding: 0 30px 50px 0;
  top: 70px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease;
  /* top: 70px; */
  transition-delay: 0.3s;
}
.profile-inner-item-border-bottom {
  opacity: 0;
}
.profile-list-item:hover .profile-inner-item-border-bottom {
  opacity: 1;
}
.active-wallet {
  opacity: 1;
}

.mega-box .content {
  /* background: white; */
  /* max-width: 1200px;
  margin: auto; */
  height: auto;

  padding: 25px 20px;
  padding-left: 210px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); */
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 35px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header {
  font-size: 12px;
  font-weight: 500;
}
.content .row .mega-links {
  /* margin-left: -40px; */
  /* border-left: 1px solid rgba(255, 255, 255, 0.09); */
}
.row .mega-links li {
  /* padding: 0 20px; */
}

.row .mega-links li a {
  padding: 0px;
  /* padding: 0 20px; */
  color: #d9d9d9;
  font-size: 12px;
  display: block;
}
.dark_color {
  color: #434343 !important;
}
.dark_color:hover {
  color: #2d2d2d !important;
}
.row .mega-links.main li a {
  padding: 0px;
  /* padding: 0 20px; */
  color: #d9d9d9;
  font-size: 20px;
  font-weight: 600;
  display: block;
}
.row .mega-links li a:hover {
  color: #f2f2f2;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.secondary_header_root {
  position: fixed;
  top: 70px;
  background: white;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  width: 100%;
  z-index: 1000;
}
.secondary_header_content {
  width: 100%;
  max-width: 1900px;
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: stretch;
}
.secondary_header_content_item {
  display: flex;
  min-width: 145px;
  margin: 0px 2.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.secondary_header_content_item:hover,
.secondary_header_content_item.active {
  border-bottom: 2px solid grey;
}
.secondary_header_content_img_container {
  width: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 05px;
}

.secondary_header_content_img_container img {
  height: 32px;
}

.link_active {
  color: white !important;
  font-size: 13px !important;
}
@media screen and (max-width: 1310px) {
  nav .wrapper {
    padding: 0 30px;
  }
  .wrapper .btn {
    display: block;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 1;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #Buy\ Crypto:checked ~ .mega-box,
  #Lotto:checked ~ .mega-box,
  #Meme:checked ~ .mega-box,
  #Hive:checked ~ .mega-box,
  #xTokens:checked ~ .mega-box,
  #Academy:checked ~ .mega-box,
  #Swap:checked ~ .mega-box,
  #Shop:checked ~ .mega-box,
  #WallStreet:checked ~ .mega-box,
  #Affiliate:checked ~ .mega-box,
  #Elite\ Club:checked ~ .mega-box,
  #Crypto\ Treasury:checked ~ .mega-box,
  #Company:checked ~ .mega-box,
  #profile:checked ~ .mega-box,
  #Exchange:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: #3a3b3c;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }
  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li {
    margin: 0;
  }
  .content .row header {
    font-size: 19px;
  }
  .secondary_header_content {
    justify-content: flex-start;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}

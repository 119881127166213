@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75%,
  100% {
    content: '...';
  }
}

.dots-animation::after {
  display: inline-block;
  content: '';
  animation: dots 1.5s infinite;
}

.hive-container {
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--body_color) !important;
  margin-top: 100px;
}

.side-container {
  padding: 30px 20px;
  border-radius: 15px;
}

.greet-container {
  width: 1100px;
  max-width: 1100px;
  /* height: 650px; */
  padding: 20px;
  margin-left: 30px;
}

.honeycomb-container {
  width: 700px;
  /* height: 650px; */
  padding: 20px;
}

.honeybee-container {
  background-color: var(--hive-honeybee);
  width: 1200px;
  /* height: 700px; */
  padding: 20px;
  margin-left: 10px;
}

.lh_32x {
  line-height: 32px;
  overflow-wrap: break-word;
}
.hexagon {
  position: relative;
  width: 140px;
  height: 144px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  margin: 0 auto;
  overflow: hidden;
}

.hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.profile-hexagon {
  position: relative;
  width: 168px;
  height: 175px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  margin: 0 auto;
  overflow: hidden;
}

.profile-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.bee-hexagon {
  position: relative;
  width: 57px;
  height: 59px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* margin: 0 auto; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bee-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: cover; Maintain aspect ratio and cover the entire hexagon */
}

.side-hexagon {
  position: relative;
  width: 77px;
  height: 79px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* margin: 0 auto; */
  overflow: hidden;
}

.side-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}
.permission {
  background-color: #ffb300;
  border-radius: 2px;
  padding: 6px 68px;
  font-size: 18px;
}

.MuiRating-iconEmpty {
  color: var(--body_color) !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--body_color) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--conf-purchase) !important;
}

.post-input .MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--body_color) !important;
  border-radius: 2px !important;
  border: 1px solid var(--border-color) !important;
  background-color: var(--body_background) !important;
}

.post-input .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.ant-picker-header button {
  width: auto;
}

.ant-picker-header button:hover,
.ant-picker-header button:focus,
.ant-picker-header button:active {
  background: none;
}

/* .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-color: #ffb300;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before
{
  background: #ffb30060;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #ffb300;
} */
.ant-picker-range .ant-picker-active-bar {
  background: #ffb300;
  height: 0px;
}

.ant-picker-input > input {
  color: var(--body_color) !important;
}

.ant-picker-panel-container {
  /* color: var(--body_color) !important; */
  background-color: var(--body_background);
}

.ant-picker-focused .ant-picker-separator,
.ant-picker-header,
.ant-picker-content th,
.ant-picker-cell,
.ant-picker-header button {
  color: var(--body_color) !important;
}

.ant-picker-cell-disabled {
  opacity: 0.25;
}
.ant-picker-focused,
.ant-picker {
  border-color: #ffb300 !important;
  box-shadow: none;
  background-color: var(--body_background);
}

.ant-picker-separator,
.ant-picker-suffix {
  color: var(--body_color);
  vertical-align: super;
}

.createDateRangePicker {
  background: var(--body_background) !important;
  color: var(--body_color) !important;
}

/* .ant-picker-panel-container{
  background: var(--body_background);
} */

.ant-picker-focused:hover {
  border-color: #ffb300;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background-color: #ffd000 !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #ffd000 !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #ffb300 !important;
}
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-color: #ffb300 !important;
  fill: #ffb300 !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.createDateRangePicker
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #ffb30060 !important;
}

.createDateRangePicker .ant-picker-cell-inner::after {
  background: #ffd000 !important;
}

.createDateRangePicker .ant-btn-primary {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
}

.com-btn {
  background-color: var(--body_background);
  color: var(--dark_text);
  border: none;
  height: 40px !important;
  width: 50% !important;
}

.com-btn:hover,
.com-btn:focus,
.com-btn:active {
  font-size: larger;
  background-color: var(--body_background);
  color: var(--dark_text);
  border: none;
}

.greet-slide-div {
  max-width: 100% !important;
  max-height: 500px !important;
}

.greet-slide-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.slick-slide {
  width: 432px !important;
}

.my-calendar {
  margin-top: 8%;
  color: var(--dark_text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .honeycomb-container {
    width: 100%;
    /* height: 650px; */
    padding: 0px;
    padding-top: 40px;
  }

  .hive-container {
    width: 100%;

    background-color: transparent;
    color: var(--body_color) !important;
  }

  .side-container {
    width: 100%;
    /* height: 650px; */
    padding: 10px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .greet-container {
    width: 100%;
    max-width: 350px;
    /* height: 650px; */
    padding: 10px;
    margin-left: 0px;
  }

  .greet-slide-div {
    max-width: auto !important;
    max-height: auto !important;
  }

  .greet-slide-img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .slick-slide {
    width: 285px !important;
  }

  .custom-table {
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
  }

  .honeybee-container {
    width: 100%;
    /* max-width: 100%; */
    padding: 10px;
  }

  .com-btn {
    font-size: 10px;
    padding-inline: 0;
  }

  .my-calendar {
    margin-top: 38%;
    color: var(--dark_text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: transparent;
    width: 80%;
    margin-inline: auto;
  }
}

.atn-btn-hover {
  background-color: #ffc233; /* Normal state color */
  border-color: #ffc233;
}

.atn-btn-hover:hover {
  background-color: #ffd55f; /* Lighter color for hover state */
  border-color: #ffd55f;
}

.link-btn {
  color: #ffb300 !important;
  background: transparent;
  width: fit-content;
}

.link-btn:hover {
  color: #ffd000 !important;
  background: transparent;
}

.font_70x {
  font-size: 70px;
}

.font_50x {
  font-size: 50px;
}

.font_31x {
  font-size: 31px;
}

.font_20x {
  font-size: 20px;
}

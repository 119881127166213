.sub-page .card-img-top {
  width: 45px;
  height: 40.86px;
  transition: trasnform 330ms ease-in-out !important;
}

.sub-page .card-img-top:hover {
  transform: scale(1.3);
  transition: trasnform 330ms ease-in-out !important;
}

.card-container {
  min-width: 137px;
  max-width: 137px;
}
.sub-page .card {
  min-width: auto !important;
  min-height: auto !important;
  align-items: center !important;
  padding: 10px 10px 10px !important;
  width: 120px !important;
  height: 80px !important;
  border: none !important;
  border-radius: 0 !important;
}

.sub-page .card-body {
  padding: 0;
}

.sub-page h5 {
  width: auto !important;
  background-color: transparent;
  border: none;
  font-size: 10px !important;
  margin-top: 8px;
  color: #000 !important;
  /* color: var(--body_color); */
}

.sub-page .card-text {
  justify-content: center;
  text-align: center;
  color: #000 !important;
  /* color: var(--body_color); */
}

.sub-logos .col {
  width: 8.5%;
}

.sub-page a {
  text-decoration: none !important;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .sub-page .card-img-top {
    width: 30px !important;
    height: 24px !important;
    transition: trasnform 330ms ease-in-out !important;
  }
  .card-container {
    min-width: 90px;
    max-width: 120px;
  }

  .sub-page .card {
    /* padding: 0 !important; */
    /* padding-top: 5px !important; */
    /* width: 80px !important; */
    /* height: 66px !important; */
    width: 90px !important;
    padding-inline: 5px !important;
    line-height: 0.5;
  }

  .sub-page .col {
    width: 25.1% !important;
    margin-top: 0;
  }

  .sub-page h5 {
    margin-top: 6px;
    font-size: 9px !important;
  }
}

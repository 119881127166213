.indexx_hive_membership_root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 100px auto;
  text-align: center;
}

.indexx_hive_membership_root h2 {
  font-weight: bold;
  font-size: 45px;
}

.indexx_hive_membership_root h4 {
  font-size: 18px;
}

.indexx_hive_membership_root p {
  font-size: 12px;
}

.power-pack-category {
  display: flex;
  padding: 20px;
  width: 100%;
  max-width: 950px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto 100px auto;
}
.power-pack-category div {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;
  padding: 10px;
}
.orange-btn {
  background: #f8c229;
}
.orange-btn:hover {
  background-color: #ffd000;
}
.yellow-btn {
  background: #8edf78;
}
.yellow-btn:hover {
  background-color: #45c921;
}
.power-pack-category div h3 {
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}
.power-pack-category div p {
  margin: 20px 0 0 0;
  text-align: center;
  font-size: 12px;
}
.power-pack-category div img {
  width: 100%;
}
.power-pack-category div button {
  margin-top: 50px;
  border-radius: 0;
  font-size: 16px;
  color: #000;
  width: 100%;
}
.power-pack-category div button:first-child:hover {
  background-color: blue;
}
.power-pack-table {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  max-width: 1100px;
  margin: 0px auto 100px auto;
}

.power-pack-table img {
  width: 100%;
}

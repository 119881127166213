.resource-page .card-img-top{
    width: 45px;
    height: 40.86px;
    transition: trasnform 330ms ease-in-out !important;
}

.resource-page .card-img-top:hover{
    transform: scale(1.3);
    transition: trasnform 330ms ease-in-out !important;
}

.resc .resource-page .card{
    min-width: auto !important;
    min-height: auto;
    align-items: center;
    padding: 10px 10px 10px;
    width: 100px;
    height: 80px;
    border: none;
    border-radius: 0;
    background-color: var(--main-body);
}

.resource-page .card-body{
    padding: 0;
}


.resc .resource-page  h5{
    width: auto !important;
    background-color: transparent;
    border: none;
    font-size: 10px !important;
    margin-top: 8px;
    color: var(--body_color) !important;
    /* color: var(--body_color); */
}

.resource-page .card-text{
    justify-content: center;
    text-align: center;
    color: var(--body_color) !important;
    /* color: var(--body_color); */
}

.up-logos .col{
    width: 7%;
}

.resource-page a{
    text-decoration: none !important;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {

    
    .resource-page .card-img-top{
        width: 30px !important;
        height: 24px !important;
        transition: trasnform 330ms ease-in-out !important;
    }

    .resc .resource-page .card{
        /* padding: 0 !important; */
        /* padding-top: 5px !important; */
        /* width: 80px !important; */
        /* height: 66px !important; */
        width: 90px !important;
        padding-inline: 5px !important;
        line-height: 0.5;
    }  
      } 
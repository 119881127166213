.App {
  text-align: center;
  background-color: var(--body_background);
  color: var(--body_color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-select-arrow {
  height: 20px;
  font-size: 19px;
  transform: rotate(-90deg);
  color: #5f5f5f;
}

.pointer-events-none {
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hive-img{
  transition: transform 330ms ease-in-out;
}

.hive-img:hover{
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

.tradingview-widget-copyright{
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.chart_inner_middle {
  font-size: 30;
}

.chart_inner_right {
  margin-top: -35px;
  margin-right: -25px;
}

.chart_inner_right .ant-segmented {
  background: var(--body_background);
  float: right;
  font-size: 15px;
  padding: 0;
  border-radius: 5px;
}

.chart_inner_right .ant-segmented:hover {
  background: var(--body_background);
  float: right;
  font-size: 15px;
  padding: 0;
  border-radius: 5px;
  /* color: rgba(var(--body_color), var(--alpha)) !important; */
}

.chart_inner_right .ant-segmented-item-label {
  width: 65px;
  font-size: 15px;
  min-height: 37px;
  line-height: 37px;
  background: var(--body_background);
  color: rgba(var(--body_color), var(--alpha)) !important;
}

.chart_inner_right .ant-segmented-item{
  background: var(--body_background);
  color: var(--body_color);
  opacity: 0.5;
}

.chart_inner_right .ant-segmented-thumb {
  background: var(--body_background) !important;
  opacity: 1;
}

.chart_inner_right .ant-segmented-item-selected {
  background: var(--dark-text);
  font-weight: bolder;
  color: var(--body_color);
  border:none;
  box-shadow: none;
  opacity: 1;
}

@media (max-width: 420px) {

  .chart_inner_right {
    margin-top: 5px;
    margin-right: 0px;
  }

}
/* Default theme */
.theme-normal {
  --primary-color: #11be6a;
  --secondary-color: #0ea55a;
  --highlight-color: lightgreen;
  --link-hover: #1890ff;
  --dropdown-hover: #11be6a;
}

/* CaptainBee theme */
.theme-captain {
  --primary-color: #ffb300;
  --secondary-color: #FFD000;
  --highlight-color: #ffb300;
  --link-hover: grey;
  --dropdown-hover: #ffb300;

}

/* HoneyBee theme */
.theme-honeyb {
  --primary-color: #ffb300;
  --secondary-color: #FFD000;
  --highlight-color: #ffb300;
  --link-hover: grey;
  --dropdown-hover: #ffb300;
}
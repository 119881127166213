.card.bs_container,
.bs_main > .card {
  border: 1px solid var(--border-color);
}

.card.bs_container {
  min-height: 500px;
}

.padding-b-1x {
  padding-bottom: 10px;
}

.margin-t-1_5x {
  margin-top: 15px;
}

.bs_container {
  background: var(--body_background);
  color: var(--body_color);
  /* background: #ffffff; */

  border: 1px solid var(--border-color);
  border-radius: 0px;
  max-width: 423px;
}

.bs_token_left {
  flex: 1;
}

.ant-select-item-group {
  padding: 0;
}

.custom-optgroup-label {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--body_color);
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  padding-block: 10px;
  width: 100%;
  padding-block: 15px;
}

.bs_curreny_left {
  flex: 1;
  text-align: center;
  font-size: 100px;
  padding: 6px 32px 20px 0;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  align-items: baseline;
  justify-content: center;
}

.bs_curreny_left > input {
  font-size: 100px;
  height: 100px;
  text-align: center;
  border: 0;
  color: #5f5f5f;
}

.bs_container_main {
  padding: 44px 41px 44px 44px;
}

button {
  width: 100%;
  background: var(--primary-color);
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  border: none;
  height: 55px;
  cursor: pointer;
}

button:hover,
button:focus,
button:active {
  background: var(--secondary-color);
}

.bs_container_header {
  margin: 0;
  font-size: 30px;
  padding: 26px;
  color: var(--body_color);
  border-bottom: 1px solid var(--border-color);
}

.d-flex {
  display: flex;
}

.bs_footer_action {
  padding: 26px;
  border-top: 1px solid var(--border-color);
}

.bs_footer_action button {
  color: #ffffff;
  /* background: rgba(95, 95, 95, 0.2); */
  background-color: var(--primary-color);
  border-radius: 0px;
  height: 55px;
}

.bs_footer_action button:hover,
.bs_footer_action button:focus,
.bs_footer_action button:active {
  background-color: var(--secondary-color);
  outline: none;
}

.bs_footer_action button.disable_icon:hover,
.bs_footer_action button.disable_icon:focus,
.bs_footer_action button.disable_icon:active {
  background-color: var(--primary-color);
}

.bs_token_num {
  font-size: 20px;
  color: var(--body_color);
}

.bs_token {
  border-top: 1px solid var(--border-color);

  padding: 20px;
}

.token_grey {
  color: var(--light-gr-color);
  padding-left: 5px;
}

.justify-between {
  justify-content: space-between;
}

.bs_purchase {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  width: fit-content;
  color: #5f5f5f;
  border: 1px solid var(--border-color);
  border-radius: 0px;
  margin: auto;
}

.bs_purchase .ant-space {
  padding: 8px 16px;
}

.bs_curreny {
  font-size: 100px;
  color: rgba(18, 18, 18, 0.5);
}

.bs_currency_symbol {
  font-size: 50px;
}

.bs_container_header.border-b-0 {
  border-bottom: 0;
}

input.search {
  border: 1px solid var(--border-color);
  border-radius: 0px;
  height: 47px;
  font-size: 17px;
  padding: 14px 15px 15px 48px;
  color: #5f5f5f;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 40%;
}

.bs_container_select_main {
  padding: 20px;
}

.position-relative {
  position: relative;
}

input + .search_icon {
  position: absolute;
  left: 35px;
  top: 33px;
  border-right: 1px solid var(--border-color);
  padding-right: 8px;
}

input::-webkit-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
}

.common_token_subTitle {
  color: rgb(95 95 95 / 20%);
  font-size: 25px;
  line-height: 1.2;
}

.bs_token_container .common_token_title {
  font-size: 25px;
  padding: 0 10px;
}

.bs_token_container {
  padding: 12px 24px;
}

.common_tokens > .bs_token_container:first-child {
  padding-left: 24px;
}

.common__token.bs_token_container {
  border-right: 0;
}

.bs_container_create_main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 285px;
  padding-top: 40px;
}

.bs_container_create_main div {
  font-size: 25px;
  color: var(--body_color);
  padding: 25px 0;
}

.bs_container_create_footer {
  padding: 25px;
}

button.ant-btn-link.btn_link span {
  color: var(--primary-color);
  font-size: 20px;
  height: 39px;
  padding-top: 16px;
}

button.ant-btn-link.btn_link span:hover {
  color: var(--secondary-color);
}

.left_arrow {
  padding-right: 8px;
  cursor: pointer;
}

.bs_container.bs_form {
  padding: 32px 16px 25px 16px;
}

/* .form_element input:not([type="checkbox"]) {
    background: #FFFFFF;
   border: 1px solid #D7D7D7;
    border-radius: 3px;
    height: 51px;
    width: 100%;
    font-size: 15px;
    padding: 0 13px;
} */

.input_height {
  height: 50px;
}

.bs_main .input_icon {
  position: absolute;
  right: 21px;
  transform: translate(50%, 50%);
}

.bs_main input[type='text'],
.ant-input-password.ant-input-affix-wrapper {
  border-color: var(--border-color);
}

.terms_conditions {
  color: #2e2d2d;
  font-size: 15px;
  padding-left: 9px;
  text-align: center;
}

.text_link {
  color: var(--primary-color);
}

.text_link:hover {
  color: var(--secondary-color);
}

input[type='checkbox'] {
  height: 38px;
  width: 28px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}

.padding-tb-2x {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-tb-3x {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-tb-4x {
  padding-top: 40px;
  padding-bottom: 40px;
}

.terms_conditions_container {
  padding-top: 10px;
  padding-bottom: 30px;
}

.justify-center {
  justify-content: center;
}

.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.verfication_text {
  width: 280px;
  font-size: 15px;
  color: var(--body_color);
}

.otp_container input {
  width: 50px;
  height: 65.48px;
  background: var(--disabled-btn);
  border: 1px solid var(--border-color);
  border-radius: 0px;
  font-size: 30px;
  text-align: center;
}

.ssn_container input {
  width: 160px;
  height: 28px;
  background: var(--disabled-btn);
  border: 1px solid var(--border-color);
  border-radius: 0px;
  font-size: 30px;
  text-align: center;
}

.send_code button {
  /* background: rgba(95, 95, 95, 0.2); */
  border-radius: 0px;
}

.bs_main label {
  font-size: 15px;
  color: var(--body_color);
}

.phone_container .ant-input-group-lg .ant-select-single .ant-select-selector,
.phone_container input {
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  height: 47px;
}

.border-0 .ant-select-selector {
  border: 0 !important;
}

.border-0.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-size: 19px;
  /* color: rgba(18, 18, 18, 0.2); */
  color: #5f5f5f;
}

.phone_container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.phone_container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 47px;
}

.phone_container_right {
  flex: 1;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  display: none;
}

.ant-dropdown-menu-item {
  padding: 10px 20px;
  font-size: 15px;
}

.ant-dropdown-menu-item:hover {
  color: var(--primary-color);
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover {
  color: #f6603653;
}

.scan-container.bs_main,
.bs_wallet {
  padding-top: 180px;
}

.default-link {
  font-size: 15px;
  line-height: 20px;
  color: var(--body_color);
}

.w-fit-content {
  width: fit-content;
}

.default-link:hover {
  color: #5f5f5f;
}

.border-always {
  border-color: var(--border-color);
  border: 1px;
}

.border-default {
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 6px 15px;
}

.border-b-1x {
  border-bottom: 1px solid var(--border-color);
}

.margin-b-2x {
  margin-bottom: 20px;
}

.card_s.card {
  min-height: 294px;
}

.card_s .verfication_text {
  font-size: 17px;
}

.sms_verfication {
  position: relative;
  right: -104%;
}

.border_separator {
  border: 1px solid #d7d7d7;
  padding: 20px;
  width: 100%;
  height: 2px;
}

.text-underline {
  text-decoration: underline;
}

.text-underline:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.text-heavy {
  font-family: AvenirHeavy;
}

.ant-input-affix-wrapper {
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  height: 51px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 0;
}

button.ant-btn-primary [disabled] {
  background-color: #5f5f5f;
  height: 55px;
  border-color: '#5F5F5F';
  color: '#fff';
  font-size: 20px;
  border-radius: 0px;
}

.bs_main .ant-btn-primary,
.bs_main .ant-btn-primary:active,
.bs_main .ant-btn-primary:focus {
  height: 55px;
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: #fff;
  font-size: 20px;
  border-radius: 0px;
}
.bs_main .ant-btn-primary:hover {
  height: 55px;
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;
  color: #fff;
  font-size: 20px;
  border-radius: 0px;
}
.bs_main .ant-btn-primary[disabled],
.bs_main .ant-btn-primary[disabled]:hover {
  background: rgba(95, 95, 95, 0.2);
  border-color: rgba(95, 95, 95, 0.2);
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 0 19px;
  padding: 20px 10px;
  font-size: 30px;
  color: var(--body_color);
  height: 80px;
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab:hover {
  color: var(--primary-color);
}

.bs_tab_item.ant-tabs {
  color: #5f5f5f;
  margin-left: 20px;
  margin-right: 20px;
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--primary-color);
}

.bs_tab_item
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.bs_main > .card {
  min-height: auto;
  /* padding-bottom: 20px; */
}

.ant-btn-dangerous.ant-btn-primary {
  background-color: var(--primary-color) !important ;
  border-color: var(--primary-color) !important ;
}

.ant-btn-dangerous.ant-btn-primary:hover {
  background-color: var(--secondary-color) !important ;
  border-color: var(--secondary-color) !important ;
}

.bs_link,
a.bs_link:hover {
  color: var(--primary-color) !important;
}

.padding-lr-0 {
  padding-left: 0;
  padding-right: 0;
}

.swap_Arrow_icon {
  position: absolute;
  right: 4px;
  top: 39%;
  opacity: 0.7;
}

.swap_Arrow_icon:hover {
  opacity: 1;
}

.bs_main .ant-btn-primary:hover {
  background-color: var(--secondary-color);
}

.bs_main .card__header {
  border-color: #d7d7d7;
  min-height: 87px;
}

.bs_main .ant-btn-primary:hover,
.bs_main .ant-btn-primary:focus,
.bs_main .footer.ant-btn-primary:hover,
.bs_main .footer .ant-btn-primary:focus {
  border-color: #d7d7d7;
  background-color: var(--secondary-color);
}

.bs_curreny_left input:hover,
.bs_curreny_left input:focus,
.bs_curreny_left input:active {
  border: 1px solid #d7d7d7;
  border: none;
  outline: none;
}

.bs_curreny_left > input.input_currency {
  max-width: 275px;
  font-size: 60px;
  font-size: 1.1ch;
  background-color: var(--body_background);
  color: var(--body_color);
}

.error_message {
  background: var(--err-clr);
  color: var(--body_color);
  border-radius: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 1rem;
  font-size: 13px;
  width: fit-content;
}

.top_heading {
  font-size: 48px;
  font-family: AvenirLight;
}

.buy_sell_bg {
  background-image: url('../../assets/arts/ladyBuyGoldImage.png');
  height: 650px;
  background-repeat: no-repeat;
  background-position: top center;
}

.responsive_container .card,
.responsive_container .bs_container {
  margin: 19px;
}

.index_link_info {
  background-color: var(--body_background);
}

.ant-form-item-required {
  color: var(--body_color) !important;
}

.notif {
  display: flex;
  justify-content: center;
  background-color: #000;
  color: #e0e0e0;
  font-size: 17px;
  font-weight: bold;
  width: 100%;
  position: fixed;
  top: 180px;
  z-index: 999;
  padding-block: 7px;
}

@media (max-width: 767px) {
  .responsive_container .card,
  .responsive_container .bs_container,
  .responsive_container.card.bs_container,
  .bs_main > .responsive_container.card {
    min-width: 100%;
    max-width: 100%;
    margin-inline: 0;
  }

  .bs_wallet .card,
  .bs_wallet .border-b-1x {
    min-width: 95%;
    border-color: #d7d7d7;
  }

  .bs_wallet .ant-input-prefix,
  .bs_wallet .orange_input.ant-input-affix-wrapper,
  .bs_wallet .orange_input.ant-input-affix-wrapper:hover,
  .bs_wallet .orange_input input,
  .orange_input input:hover {
    border-color: #d7d7d7 !important;
  }

  .bs_wallet .padding-2x {
    padding: 0;
  }

  .bs_main label,
  .placeholder_info {
    color: #5f5f5f !important;
  }

  .responsive_container .card,
  .responsive_container.card,
  .responsive_container.card.bs_container,
  .bs_main > .responsive_container.card {
    border: none;
  }

  .responsive_container .bs_container.bs_form {
    padding: 0 16px;
    /* margin-inline: auto !important; */
  }

  .responsive_container .top_heading {
    font-size: 35px;
  }

  .responsive_container h1 {
    font-size: 25px;
  }

  .responsive_container .secure-steps .font_20x {
    font-size: 13px;
  }

  .responsive_container .secure-steps .d-flex img {
    width: 40px;
    height: 40px;
  }

  .two-factor-text {
    font-size: 15px;
  }

  .verfication_text {
    font-size: 11px;
  }

  .verfication_text.email_verification {
    font-size: 13px;
  }

  .scan-container.bs_main {
    padding-top: 160px;
  }

  .bs_container.card {
    padding: 0 1rem;
  }

  .card.bs_container,
  .bs_main > .card {
    border: none;
  }

  .bs_curreny_left .font_60x {
    font-size: 60px;
  }

  .with-graph {
    flex-direction: column;
    height: max-content;
    min-height: 500px !important;
  }

  .notif {
    font-size: 12px;
    width: 100vw;
    top: 146px;
    padding-inline: 10px;
    text-align: center;
  }
}

.bs_main .hive-btn {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
  font-size: 20px;
  height: 55px;
  color: #fff;
  border-radius: 0;
}

.bs_main .hive-btn:hover,
.bs_main .hive-btn:focus,
.bs_main .hive-btn:active {
  background-color: #ffd000 !important;
  border-color: #ffd000 !important;
}

.blk_yl_link {
  color: var(--body_color);
}

.blk_yl_link:hover {
  color: #ffb300;
  cursor: pointer;
}

.hive_link {
  color: #ffb300;
}
.login_links {
  display: block;
  width: 100%;
  height: 55px;
  background: #ffb300;
  color: #fff;
  text-align: center;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.2s linear;
}
.login_links:hover {
  background: #ffd000;
  color: #fff;
}

.hive_link:hover {
  color: #ffd000;
  cursor: pointer;
}

.walls {
  transition: transform 330ms ease-in-out;
}

.walls:hover {
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

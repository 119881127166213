.trade_to_earn_banner {
    display: flex;
    padding: 40px 120px 0 0;
    margin: 50px 0;
    flex-direction: column;
    height: auto;
    background-image: url("../../../assets/arts/TradeBannerBG.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.trade_left {
    width: 300px;
    height: 300px;
    margin-left: 20%;
    align-self: flex-end;
    background-image: url("../../../assets/arts/tradeEarnCoin.png");
    background-size: auto;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-position-x: right;
}

.trade_right {
    padding-bottom: 35px;
}

.line_height_1 {
    line-height: 1;
}

.mega_heading {
    font-size: 80px;
    font-family: AvenirMedium;
}

.flex-direction-row {
    flex-direction: row;
}

.text-green {
    color: #8BE64B;
}

.tm_text {
    font-size: 15px;
    position: relative;
    bottom: 0;
}

.trade_percentage {
    font-family: AvenirHeavy;
    font-size: 130px;
    margin-top: 20px;
}

.earn_btn {
    font-size: 22px;
    min-height: 43px;
    margin: 35px auto 0;
}

.ant-btn.btn-success.earn_btn {
    max-width: 325px;
}

@media (max-width: 767px) {

    .trade_percentage {
        font-size: 55px !important;
    }

    .trade_percentage span {
        font-size: 30px !important;
    }

    .trade_left {
        width: 110px;
        height: 98px;
    }

    .ant-btn.btn-success.earn_btn {

        max-width: calc(100% - 30px);
    }



    .tm_text {
        font-size: 7px;
    }

    .mega_heading.text-white {
        font-size: 25px !important;
    }

    .mega_heading.trade_percentage .mega_heading.trade_percentage span {
        font-size: 30px !important;
    }

    .trade_to_earn_banner h1.text-white {
        font-size: 15px;
    }

    .trade_to_earn_banner {

        padding-right: 35px;
    }

    .trade_left {
        display: none;
    }

    .trade_right {
        margin-right: auto;
        margin-left: auto;
    }
}
.notification_container .border-b,
.help_content_page.notification_container{
    border-color:#E4E4E4;
}

@media (max-width: 767px) {
.nav_container.notification_nav_container {
    padding: 2px 10px 40px 10px;
} 

.nav_main_header>div:last-child{
    border:none;
}
}

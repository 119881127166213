.folder{
    background-image: url('../../../../assets/hive-dashboard/folder.png');
    background-repeat: no-repeat;
    min-width: 89px;
    min-height: 75px;
}

.folder:hover{
    background-image: url('../../../../assets/hive-dashboard/mouse\ over.png');

}
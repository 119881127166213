.error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10rem;
    padding-right: 6rem;
  }
  h5 {
    width: 470px;
    border: 1px red solid;
    background-color: rgb(214, 77, 77);
    text-align: center;
    font-size: 1rem;
  }
  
  @media only screen and (max-width: 500px) {
    .error {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 7rem;
      padding-right: 0rem;
    }
    h5 {
      width: 170px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    .error {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 15rem;
      padding-right: 0rem;
    }
    h5 {
      width: 170px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .error {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 20rem;
      padding-right: 0rem;
    }
    h5 {
      width: 370px;
      border: 1px red solid;
      background-color: rgb(214, 77, 77);
      text-align: center;
    }
  }
  